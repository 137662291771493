<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 2)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent">
      <v-toolbar-title>Propostas de Veículos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="mostrarTudo=!mostrarTudo"
            color="info"
            dark
            fab
            small
            class="mr-4"
          >
            <v-icon>{{ mostrarTudo ?  'group' : 'group_off' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ mostrarTudo ? 'Mostrar primeiro da fila' : 'Mostrar todos da fila' }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab color="info" @click="showFilter = !showFilter">
            <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
          </v-btn>
        </template>
        <span>{{!showFilter ? 'Mostrar filtros' : 'Recolher filtros'}}</span>
      </v-tooltip>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field
                    label="Código"
                    v-model="id_veiculo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    label="Status"
                    :items="
                      [
                        { text: 'Aguardando', value: null },
                        { text: 'Aceita', value: true },
                        { text: 'Rejeitada', value: false }
                      ]
                    "
                    v-model="status"
                    item-text="text"
                    item-value="value"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    label="Enviado por"
                    :items="
                      [
                        { text: 'Você', value: 1 },
                        { text: 'Cliente', value: 2 },
                        { text: 'Empresa', value: 3 }
                      ]
                    "
                    v-model="isCliente"
                    item-text="text"
                    item-value="value"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                      :items="options"
                      item-text="title"
                      item-value="value"
                      v-model="selectOpcoes"
                    >
                    </v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-menu
                    ref="menuDataInicial"
                    v-model="menuDataInicial"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-combobox
                        v-if="opcoes.custom"
                        v-model="opcoes.intervalo_relatorio"
                        :label="status === null || status === undefined ? 'Dias de Envio' : 'Dias de Encerramento'"
                        multiple
                        persistent-hint
                        chips
                        small-chips
                        readonly
                        flat
                        prepend-icon="event"
                        required
                        v-on="on"
                        clearable
                      ></v-combobox>
                      <v-combobox
                        v-else-if="opcoes.dia"
                        v-model="opcoes.data_relatorio"
                        :label="status === null || status === undefined ? 'Data de Envio' : 'Data de Encerramento'"
                        persistent-hint
                        chips
                        small-chips
                        readonly
                        flat
                        @blur="date = parseDate(opcoes.data_relatorio)"
                        prepend-icon="event"
                        required
                        v-on="on"
                        clearable
                      ></v-combobox>
                      <v-combobox
                        v-else
                        v-model="opcoes.mes_relatorio"
                        :label="status === null || status === undefined ? 'Mês de Envio' : 'Mês de Encerramento'"
                        persistent-hint
                        chips
                        small-chips
                        readonly
                        flat
                        @blur="month = parseDate(opcoes.mes_relatorio)"
                        prepend-icon="event"
                        required
                        v-on="on"
                        clearable
                      ></v-combobox>
                    </template>
                    <v-date-picker v-show="opcoes.custom" multiple v-model="dates" type="date" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
                    <v-date-picker v-show="opcoes.dia" v-model="date" type="date" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
                    <v-date-picker v-show="opcoes.mes" v-model="month" type="month" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn fab small color="info" @click="carregarPropostas(true)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
      <v-data-table
        hide-default-footer
        :headers="propostaHeaders"
        :items="propostas"
        :options.sync="pagination"
        no-data-text="Você ainda não possui Propostas"
        class="elevation-1"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">
              <v-icon>handshake</v-icon>
            </td>
            <td class="text-xs-left">
              {{props.item.veiculo ? props.item.veiculo.id_veiculo : ''}}
            </td>
            <td class="text-xs-left">
              <template v-if="props.item.veiculo">
                {{ props.item.veiculo.marca }} {{ props.item.veiculo.modelo }}
              </template>
            </td>
            <td>
              {{ props.item.solicitacao.data | formatDate('DD/MM/YYYY')  }}
            </td>
            <td>{{ props.item.solicitacao.data_encerramento | formatDate('DD/MM/YYYY') }}</td>
            <td>
              {{
                props.item.empresa && props.item.empresa.cnpj === empresa.cnpj  ? 'Você' :  (props.item.cliente ? 'Usuário' : 'Empresa')
              }}
            </td>
            <td class="text-xs-left">
              <v-tooltip bottom v-if="props.item.solicitacao.status==true">
                <template v-slot:activator="{ on }">
                  <v-icon color="teal" dark v-on="on">check_circle_outline</v-icon>
                </template>
                <span>Aceita.</span>
              </v-tooltip>
              <v-tooltip bottom v-if="props.item.solicitacao.status==null">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on">query_builder</v-icon>
                </template>
                <span>Aguardando Resposta.</span>
              </v-tooltip>
              <v-tooltip bottom v-if="props.item.solicitacao.status==false">
                <template v-slot:activator="{ on }">
                  <v-icon color="red" dark v-on="on">highlight_off</v-icon>
                </template>
                <span>Recusado.</span>
              </v-tooltip>
            </td>
            <td class="text-xs-left">
              <v-tooltip bottom v-if="props.item.solicitacao.finalizado">
                <template v-slot:activator="{ on }">
                  <v-icon color="teal" dark v-on="on">check_circle_outline</v-icon>
                </template>
                <span>Finalizada</span>
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on">query_builder</v-icon>
                </template>
                <span>Aguardando Finalização por {{
                  props.item.empresa && props.item.empresa.cnpj === empresa.cnpj  ? 'Você' :  (props.item.cliente ? 'Usuário' : 'Empresa')
                }}
                </span>
              </v-tooltip>
            </td>
            <td class="text-xs-right">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    color="primary"
                    @click="
                      propostaSelecionada = props.item,
                      dialogVisualizarProposta = true
                    "
                  >
                    <v-icon>remove_red_eye</v-icon>
                  </v-btn>
                </template>
                <span>Detalhes da Proposta</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    color="info"
                    @click="
                      propostaSelecionada = props.item,
                      dialogEditarProposta = true
                    "
                    :disabled="
                      props.item.solicitacao.status !== null ||
                      (
                        !props.item.solicitacao.empresa ||
                        !empresa ||
                        props.item.solicitacao.empresa.cnpj !== empresa.cnpj
                      )
                    "
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar Proposta</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    color="red"
                    @click="
                      propostaSelecionada = props.item,
                      dialogExcluirProposta = true
                    "
                    :disabled="props.item.solicitacao.status !== null"
                  >
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </template>
                <span>Excluir proposta</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    <div class="text-xs-center">
      <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
    </div>
    <v-dialog
      v-model="dialogVisualizarProposta"
      v-if="dialogVisualizarProposta"
      width="55em"
      scrollable
    >
      <visualizar-proposta-veiculo
        @fechar="
          dialogVisualizarProposta = false,
          propostaSelecionada = undefined
        "
        @carregar="carregarPropostas(false)"
        :propostaSelecionada="propostaSelecionada"
        />
    </v-dialog>
    <v-dialog v-model="dialogExcluirProposta" width="500">
      <v-card>
        <v-card-title class="justify-center error--text" style="font-size: 1.3em;">
          Você deseja excluir essa proposta?
        </v-card-title>
        <v-card-actions class="justify-space-around">
          <v-btn rounded class="mb-3" @click="dialogExcluirProposta = false">Não</v-btn>
          <v-btn rounded class="mb-3" @click="excluirProposta" color="error">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditarProposta" v-if="dialogEditarProposta" width="50em">
      <editar-proposta-veiculo
        @fechar="dialogEditarProposta = false, propostaSelecionada = undefined"
        :proposta="propostaSelecionada"
        @carregar="carregarPropostas(false)"
      />
    </v-dialog>
  </v-layout>
</template>
<script>
import VisualizarPropostaVeiculo from '@/components/dialogs/VisualizarPropostaVeiculo'
import EditarPropostaVeiculo from '@/components/dialogs/EditarPropostaVeiculo'
import moment from 'moment'
import AvisoBloqueio from '@/components/AvisoBloqueio'
export default {
  components: { VisualizarPropostaVeiculo, EditarPropostaVeiculo, AvisoBloqueio },
  data () {
    return {
      date: moment().format('YYYY-MM-DD'),
      month: new Date().toISOString().substr(0, 10),
      dialogEditarProposta: false,
      dialogVisualizarProposta: false,
      dialogExcluirProposta: false,
      propostaSelecionada: undefined,
      menuDataInicial: false,
      showFilter: false,
      status: undefined,
      data_inicia: undefined,
      id_veiculo: undefined,
      data_encerramento: undefined,
      isCliente: undefined,
      mostrarTudo: true,
      totalItens: 0,
      propostas: [],
      dates: [],
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      options: [
        {
          title: 'Dia',
          value: 'dia'
        },
        {
          title: 'Mês',
          value: 'mes'
        },
        {
          title: 'Intervalo',
          value: 'custom'
        }
      ],
      selectOpcoes: 'dia',
      opcoes: {
        dia: true,
        custom: false,
        mes: false,
        data_relatorio: moment().format('DD/MM/YYYY'),
        mes_relatorio: null,
        intervalo_relatorio: [],
        confirmadas: true
      },
      propostaHeaders: [
        {
          sortable: false
        },
        {
          text: 'Código do veículo',
          value: 'veiculo.identificacao',
          sortable: false
        },
        {
          text: 'Nome do veículo',
          value: 'veiculo.marca',
          sortable: false
        },
        {
          text: 'Data',
          align: 'left',
          sortable: false,
          value: 'solicitacao.data'
        },
        {
          text: 'Data de encerramento',
          align: 'left',
          sortable: false,
          value: 'solicitacao.data_encerramento'
        },
        {
          text: 'Oferecida por',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Status',
          value: 'solicitacao.status',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Finalizado',
          value: 'solicitacao.finalizado',
          sortable: false,
          align: 'left'
        },
        {
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  watch: {
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarPropostas(false)
      }
    },
    pagination: {
      handler (val, oldval) {
        this.carregarPropostas(false)
      },
      deep: true
    },
    mostrarTudo () { this.carregarPropostas(false) },
    date (val) {
      if (val) {
        this.opcoes.data_relatorio = this.formatDate(this.date)
      }
    },
    month (val) {
      if (val) {
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    },
    selectOpcoes (val) {
      this.limparOpcoes()
      if (val === 'dia') {
        this.opcoes.dia = true
        this.opcoes.data_relatorio = this.formatDate(this.date)
      } else if (val === 'custom') {
        this.opcoes.custom = true
        this.opcoes.data_relatorio = this.formatDate(this.date)
      } else if (val === 'mes') {
        this.opcoes.mes = true
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    }
  },
  mounted () {
    if (this.empresa) {
      this.carregarPropostas(false)
    }
  },
  methods: {
    limparOpcoes () {
      this.opcoes.dia = false
      this.opcoes.custom = false
      this.opcoes.mes = false
    },
    carregarPropostas (isBtn) {
      if (!this.empresa) return
      const data = {
        cnpj: this.empresa.cnpj
      }
      data.page = isBtn ? 1 : this.pagination.page
      if (!this.mostrarTudo) data.lista_espera = this.mostrarTudo
      if (this.status !== undefined) {
        if (this.status === null) data.solicitacao__status__isnull = true
        else data.solicitacao__status = this.status
      }
      if (isBtn) {
        if (this.opcoes.custom && this.opcoes.intervalo_relatorio && this.opcoes.intervalo_relatorio.length === 2) {
          if (this.status === null) {
            data.solicitacao__data__gte = this.opcoes.intervalo_relatorio[0]
            data.solicitacao__data__lte = this.opcoes.intervalo_relatorio[1]
          } else {
            data.solicitacao__data_encerramento__gte = this.opcoes.intervalo_relatorio[0]
            data.solicitacao__data_encerramento__lte = this.opcoes.intervalo_relatorio[1]
          }
        } else if (this.opcoes.dia && this.date) {
          const dataBusca = this.status === null ? 'solicitacao__data' : 'solicitacao__data_encerramento'
          data[dataBusca] = this.date.split('-').reverse().join('/')
        } else if (this.opcoes.mes && this.month && this.month.length) {
          const initialDate = new Date(`${this.month.split('-')[1]}-01-${this.month.split('-')[0]}`.split('-').join('/'))
          const finalDate = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0)

          if (this.status === null) {
            data.solicitacao__data__gte = initialDate.toISOString().substr(0, 10).split('-').reverse().join('/')
            data.solicitacao__data__lte = finalDate.toISOString().substr(0, 10).split('-').reverse().join('/')
          } else {
            data.solicitacao__data_encerramento__gte = initialDate.toISOString().substr(0, 10).split('-').reverse().join('/')
            data.solicitacao__data_encerramento__lte = finalDate.toISOString().substr(0, 10).split('-').reverse().join('/')
          }
        }
      }

      if (this.id_veiculo) data.veiculo = this.id_veiculo
      if (this.isCliente === 1) data.solicitacao__empresa = this.empresa.cnpj
      if (this.isCliente === 2) {
        data.solicitacao__cliente__isnull = false
        data.empresa = this.empresa.cnpj
      }
      if (this.isCliente === 3) {
        data.solicitacao__empresa__isnull = false
        data.empresa = this.empresa.cnpj
      }
      this.$store.dispatch('pegarPropostasVeiculos', data).then(json => {
        this.propostas = json.results
        this.totalItens = json.count
      })
    },
    excluirProposta () {
      if (!this.propostaSelecionada) this.$store.dispatch('setError', 'Proposta não encontrada.')
      this.$store.dispatch('solicitacaoVeiculo', {
        method: 'DELETE',
        id: this.propostaSelecionada.id
      }).then(() => {
        this.carregarPropostas(false)
        this.dialogExcluirProposta = false
      })
    },
    dataSelecionada () {
      if (this.opcoes.custom) {
        if (this.dates.length === 3) {
          const day1 = this.dates[0]
          const day2 = this.dates[2]
          this.dates = []
          this.dates = [day1, day2]
        }
        if (this.dates.length) {
          this.orderDates()
        }
        this.opcoes.intervalo_relatorio = this.dates.map(this.formatDate)
      } else {
        this.menuDataInicial = false
      }
    },
    formatDate (date) {
      if (!date) return null

      if (this.opcoes.dia || this.opcoes.custom) {
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      } else {
        const [year, month] = date.split('-')
        return `${month}/${year}`
      }
    },
    parseDate (date) {
      if (!date) return null

      if (this.opcoes.dia || this.opcoes.custom) {
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else {
        const [month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}`
      }
    },
    orderDates () {
      this.dates.sort((a, b) => {
        a = new Date(a.split('-'))
        b = new Date(b.split('-'))
        return a < b ? -1 : a > b ? 1 : 0
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
