<template>
  <v-dialog
    v-model="ativo"
    @click:outside="ativo=false"
    @keydown.esc="
        ativo = false
    "
    width="40em"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          small
          :color="veiculo.destinatario_oferta === 1 ? 'success' : 'info'"
          rounded
      >
        {{ { 1: 'Clientes', 2: 'Revendas' }[veiculo.destinatario_oferta] }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        <h1>ANUNCIO DE VEÍCULO</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-alert
        v-show="alerta"
        :type="tipo"
        class="mt-4 mb-1 pa-2"
        :value="true"
      >
        <h4 justify-center>{{alerta}}</h4>
      </v-alert>
      <v-card-text>
        <h3 class="mt-4 mb-4 success--text">
          Esse o destino do anúncio desse veículo é para
          {{
            destino === 2 ?
            'empresa revendedoras ou concessionárias'
            :
            'clientes ou usúarios'
          }}.
        </h3>
        <v-flex xs12 class="mt-4">
          <v-select
            label="Trocar destino de anúncio"
            v-model="destino"
            outlined
            :items="[
              { text: 'clientes ou usúarios', value: 1 },
              { text: 'Revendedoras ou concessionárias', value: 2 },
            ]"
          ></v-select>
        </v-flex>
        <h2
          class="text-center"
          style="margin-bottom: 2em;"
          v-if="destino === 2"
        >
          SELECIONE UMA OPÇÃO
        </h2>
        <v-layout
          class="mt-4 mb-4"
          justify-space-around
          style="width: 100%;"
          wrap
          v-if="destino === 2"
        >
          <v-flex xs6>
            <v-btn
              outlined
              block
              :text="!todas"
              color="info"
              @click="todas = true"
              :class="todas ?'white--text' : ''"
            >
              Todas as empresas
            </v-btn>
          </v-flex>
          <v-flex xs6>
            <v-btn
              outlined
              block
              :text="todas"
              color="info"
              @click="todas = false"
              :class="!todas ?'white--text' : ''"
            >
              Empresas selecionadas
            </v-btn>
          </v-flex>
          <v-flex xs12>
            <v-slide-x-transition>
              <v-layout column v-if="destino == 2 && !todas">
                <v-layout justify-end class="mr-2 mb-4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        fab
                        small
                        color="info"
                        class="white--text"
                        @click="filtroAtivo = !filtroAtivo"
                      >
                        <v-icon>{{ filtroAtivo ? 'filter_list_off' : 'filter_list' }}</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ filtroAtivo ? 'Esconder filtro' : 'Mostrar filtro' }}
                    </span>
                  </v-tooltip>
                </v-layout>
                <v-slide-x-transition>
                  <v-card class="ma-2" v-show="filtroAtivo">
                    <v-card-actions class="justify-center d-inline-flex">
                      <v-flex xs4>
                        <v-text-field
                          label="Nome"
                          color="info"
                          v-model="nome_fantasia"
                        />
                      </v-flex>
                      <v-flex xs3>
                        <v-text-field
                          label="Cidade"
                          color="info"
                          v-model="cidade"
                        />
                      </v-flex>
                      <v-flex xs3>
                        <v-select
                          label="Estado"
                          color="info"
                          :items="estados"
                          v-model="estado"
                          clearable
                        />
                      </v-flex>
                      <v-flex xs1>
                        <v-btn
                          fab
                          color="info"
                          small
                          class="white--text"
                          @click="pegarRevendedoras(true)"
                        >
                          <v-icon>search</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-card-actions>
                  </v-card>
                </v-slide-x-transition>
                <v-list>
                  <v-list-item
                    v-for="(empresa, index) in empresas"
                    :key="index"
                    @click="function () {
                      const temp = isSelected(empresa)
                      if (temp) removerEmpresa(empresa)
                      else adicionarEmpresa(empresa)
                    }"
                    class="ma-1"
                    :style="isSelected(empresa) ? {'border': '1px solid #2196f3'} : {}"
                  >
                    <v-list-item-avatar>
                      <v-icon color="info">business</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ empresa.nome_fantasia }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ empresa.cnpj }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-icon color="info">map</v-icon>
                        <span v-if="empresa.endereco" class="ml-1">{{ empresa.endereco.cidade }}/{{ empresa.endereco.estado }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div class="text-xs-center">
                  <v-pagination
                    v-model="pagination_empresas.page"
                    :length="pages"
                    circle
                    color="info"
                  ></v-pagination>
                </div>
              </v-layout>
            </v-slide-x-transition>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-space-around">
        <v-flex>
          <v-btn @click="ativo = false" block text>
            Fechar
          </v-btn>
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex xs6>
          <v-btn block color="success" text :loading="salvando" @click="salvar">
            Salvar
          </v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money'
import { veiculoDao } from '@/api'
export default {
  directives: { money: VMoney },
  props: ['veiculo'],
  data: () => ({
    destino: 1,
    empresas_selecionadas: [],
    pagination_empresas: {
      page: 1,
      rowsPerPage: 5
    },
    empresas: [],
    totalItens: 0,
    todas: true,
    nome_fantasia: undefined,
    estado: undefined,
    cidade: undefined,
    ativo: false,
    alerta: undefined,
    salvando: false,
    filtroAtivo: false,
    tipo: 'error',
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    }
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    headers () {
      return this.$store.getters.headers
    },
    estados () {
      return this.$store.getters.estados
    },
    pages () {
      const count = this.totalItens
      if (this.pagination_empresas.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination_empresas.rowsPerPage)
    }
  },
  beforeMount () {
    this.destino = this.veiculo.destinatario_oferta
    this.empresas_ofertadas = this.veiculo.empresas_ofertadas
    if (this.empresas_selecionadas.length > 0) this.todas = false
  },
  watch: {
    destino (val) {
      if (val === 1) this.empresas_selecionadas = []
      else this.pegarRevendedoras(false)
    },
    pagination_empresas: {
      handler (val, oldval) {
        if (this.empresa && this.destino === 2) this.pegarRevendedoras(false)
      },
      deep: true
    },
    todas (val) {
      if (val) this.empresas_selecionadas = []
    }
  },
  methods: {
    activeAlert (text, type) {
      this.alerta = text
      this.tipo = type
      const interval = setInterval(() => {
        this.alerta = undefined
        clearInterval(interval)
      }, 5000)
    },
    async salvar () {
      this.salvando = true
      await veiculoDao.updateForEmpresa(
        {
          destinatario_oferta: this.destino,
          empresa: this.empresa.cnpj,
          empresas_ofertadas: this.empresas_selecionadas
        },
        this.veiculo.id_veiculo,
        this.headers
      )
        .then(response => {
          if (response.ok) {
            this.veiculo.destinatario_oferta = this.destino
            this.activeAlert('As informações do veículo foram alteradas com sucesso', 'info')
            this.$emit('carregar')
          } else {
            this.$emit('Desculpe, não foi possível modificar o veículo no momento', 'error')
          }
        })
      this.salvando = false
    },
    adicionarEmpresa (empresa) {
      if (!empresa) {
        this.activeAlert('Empresa inválida', 'error')
        return
      }
      const existe = !!this.empresas_selecionadas.find((item) => item === empresa.cnpj)
      if (existe) {
        this.activeAlert('Essa empresa já foi inserida', 'error')
        return
      }
      this.empresas_selecionadas.push(empresa.cnpj)
    },
    removerEmpresa (empresa) {
      if (!empresa) {
        this.activeAlert('Empresa inválida', 'error')
        return
      }
      const index = this.empresas_selecionadas.indexOf(empresa.cnpj)
      if (index === -1) {
        this.activeAlert('Essa empresa não foi inserida a lista.', 'error')
        return
      }
      this.empresas_selecionadas.splice(index, 1)
    },
    isSelected (empresa) {
      return !!this.empresas_selecionadas.find((item) => item === empresa.cnpj)
    },
    pegarRevendedoras (isBtn) {
      const data = {
        tipo_empresa: 2,
        empresa: this.empresa.cnpj
      }
      data.page = isBtn ? 1 : this.pagination_empresas.page
      if (this.cidade) data.endereco__cidade__icontains = this.cidade
      if (this.estado) data.endereco__estado__icontains = this.estado
      if (this.nome_fantasia) data.nome_fantasia__icontains = this.nome_fantasia
      this.$store.dispatch('pegarEmpresasAtivas', data).then(json => {
        this.empresas = json.results
        this.totalItens = json.count
      })
    } // pegarRevendedoras
  }
}
</script>
