<template>
  <v-dialog
    v-model="ativo"
    @click:outside="ativo=false"
    @keydown.esc="
        ativo = false
    "
    width="40em"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          small
          :color="tem_leilao ? 'success' : ''"
          rounded
      >
        {{ tem_leilao ? 'Habilitado' : 'Desabilitado' }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        <h1>DISPUTA DE LANCES</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-alert
        v-show="alerta"
        :type="tipo"
        class="mt-4 mb-1 pa-2"
        :value="true"
      >
        <h4 justify-center>{{alerta}}</h4>
      </v-alert>
      <v-card-text>
        <h3 class="text-center mt-4" v-if="!tem_leilao && !slide_deletar && !formulario">
          No momento é esse veículo não possui uma disputa habilitada
        </h3>
        <v-flex class="text-center mt-4 mb-4">
          <v-btn
              v-if="!slide_deletar"
              :color="tem_leilao || slide_deletar || formulario ? '' : 'success'"
              rounded
              :fab="formulario && !tem_leilao"
              :x-small="formulario && !tem_leilao"
              @click="function () {
                if (tem_leilao) {
                  formulario = false
                  slide_deletar = true
                } else {
                  slide_deletar = false
                  formulario = !formulario
                }
              }"
          >
              <template v-if="tem_leilao">
                desabilitar
              </template>
              <template v-else>
                {{ formulario ? 'X' : 'Habilitar' }}
              </template>
          </v-btn>
        </v-flex>
        <v-slide-x-transition>
          <v-form lazy-validation v-model="validoLeilao" ref="formularioLeilao" v-show="formulario">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  :disabled="tem_leilao"
                  v-model.lazy="leilao.valor_inicial"
                  v-money="money"
                  required
                  prefix="R$"
                  label="Valor Inicial"
                />
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menuDataInicio"
                  v-model="menuDataInicio"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Data de início"
                      v-model="leilao.data_inicio"
                      prepend-icon="mdi-calendar"
                      required
                      readonly
                      v-on="on"
                      :rules="[
                          (v) => !!v || 'A data inicial é obrigatória'
                        ]"
                    />
                  </template>
                  <v-date-picker
                    v-model="data_inicio"
                    no-title
                    @input="menuDataInicio = false"
                    locale="pt-br"
                    :allowed-dates="(date) => {
                      return Date.parse(date) >= today
                    }"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menuDataEncerramento"
                  v-model="menuDataEncerramento"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Data de encerramento"
                      v-model="leilao.data_fim"
                      prepend-icon="mdi-calendar"
                      required
                      readonly
                      v-on="on"
                      :rules="[
                        (v) => !!v || 'A data de encerramneto é obrigatória'
                      ]"
                    />
                  </template>
                  <v-date-picker
                    v-model="date_encerramento"
                    no-title
                    @input="menuDataEncerramento = false"
                    locale="pt-br"
                    :allowed-dates="(date) => {
                      return testarDataFim(date)
                    }"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menuHorarioInicio"
                  v-model="menuHorarioInicio"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="leilao.horario_inicio"
                      required
                      label="Horario de ínicio"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[
                        (v) => !!v || 'A horário de inácia é obrigatória'
                      ]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuHorarioInicio"
                    v-model="leilao.horario_inicio"
                    full-width
                    no-title
                    @click:minute="menuHorarioInicio = false"
                    format="24hr"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menuHorarioEncerramento"
                  v-model="menuHorarioEncerramento"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      required
                      v-model="leilao.horario_fim"
                      label="Horario de encerramento"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[
                        (v) => !!v || 'A horário de encerramento é obrigatória'
                      ]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuHorarioEncerramento"
                    v-model="leilao.horario_fim"
                    full-width
                    no-title
                    @click:minute="menuHorarioEncerramento = false"
                    format="24hr"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-form>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <v-flex xs12 v-show="slide_deletar">
            <h3 class="text-center ma-4 error--text">Tem certeza que deseja desabilitar essa disputa de lances?</h3>
          </v-flex>
        </v-slide-x-transition>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-space-around">
        <template v-if="!tem_leilao || (tem_leilao && !slide_deletar)">
          <v-flex :xs6="formulario" :xs12="!formulario">
            <v-btn @click="ativo = false" block text>
              Fechar
            </v-btn>
          </v-flex>
          <v-divider vertical v-if="formulario"></v-divider>
          <v-flex xs6 v-if="formulario">
            <v-btn @click="salvar" block color="success" text>
              Salvar
            </v-btn>
          </v-flex>
        </template>
        <template v-else>
          <v-flex xs6>
            <v-btn @click="slide_deletar = false, formulario = true" block text>
              Não
            </v-btn>
          </v-flex>
          <v-divider vertical></v-divider>
          <v-flex xs6>
            <v-btn @click="deletar" block color="error" text>
              Sim
            </v-btn>
          </v-flex>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money'
export default {
  directives: { money: VMoney },
  props: ['veiculo'],
  data: () => ({
    ativo: false,
    alerta: undefined,
    tipo: 'error',
    formulario: false,
    slide_deletar: false,
    validoLeilao: false,
    tem_leilao: false,
    menuDataInicio: false,
    menuDataEncerramento: false,
    menuHorarioEncerramento: false,
    menuHorarioInicio: false,
    data_inicio: undefined,
    date_encerramento: undefined,
    today: undefined,
    leilao: {
      id: undefined,
      veiculo: undefined,
      data_inicio: undefined,
      data_fim: undefined,
      horario_inicio: undefined,
      horario_fim: undefined,
      status: 1,
      valor_inicial: undefined
    },
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    }
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  beforeMount () {
    this.today = new Date().toISOString().substr(0, 10)
    this.today = Date.parse(this.today)
    if (this.veiculo.leilao) {
      this.formatarDadosLeilao(this.veiculo.leilao)
    }
  },
  watch: {
    data_inicio (val) {
      this.leilao.data_inicio = this.formatDate(val)
      if (this.date_encerramento && Date.parse(this.data_inicio) > Date.parse(this.date_encerramento)) {
        this.date_encerramento = val
      }
    },
    date_encerramento (val) {
      this.leilao.data_fim = this.formatDate(val)
      if (!this.leilao.data_inicio || Date.parse(this.data_inicio) > Date.parse(val)) {
        this.data_inicio = val
      }
    },
    tem_leilao (val) {
      if (!val) {
        this.data_inicio = undefined
        this.date_encerramento = undefined
        this.leilao.horario_inicio = undefined
        this.leilao.horario_fim = undefined
      }
    },
    ativo (val) {
      if (val) {
        if (this.veiculo.leilao) this.formulario = true
        this.pegarLeilao()
      }
    }
  },
  methods: {
    activeAlert (text, type) {
      this.alerta = text
      this.tipo = type
      const interval = setInterval(() => {
        this.alerta = undefined
        clearInterval(interval)
      }, 5000)
    },
    is_number (value) {
      try {
        const teste = Number(value)
        if (!teste) return false
        return true
      } catch (e) {
        return false
      }
    },
    converterParaNumero (valor) {
      if (!valor) return 0
      if (this.is_number(valor)) {
        return Number(valor)
      } else {
        return Number(valor.replaceAll('.', '').replaceAll(',', '.'))
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formatarDadosLeilao (dados) {
      this.tem_leilao = true
      const dataInicioSeparada = dados.data_inicio.split('T')
      const dataFimSeparada = dados.data_fim.split('T')
      this.leilao = {
        ...dados,
        data_inicio: this.formatDate(dataInicioSeparada[0]),
        data_fim: this.formatDate(dataFimSeparada[0]),
        horario_inicio: dataInicioSeparada[1].substring(0, 5),
        horario_fim: dataFimSeparada[1].substring(0, 5)
      }
      this.data_inicio = dataInicioSeparada[0]
      this.date_encerramento = dataFimSeparada[0]
    },
    pegarLeilao () {
      const data = {
        method: 'GET',
        parametros: {
          empresa: this.empresa.cnpj,
          returnObject: true,
          status__in: [1, 4],
          veiculo_leiloado: this.veiculo.id_veiculo
        }
      }
      this.$store.dispatch('leilaoVeiculos', data).then(json => {
        this.formatarDadosLeilao(json)
      })
    },
    deletar () {
      const data = {
        method: 'DELETE',
        id: this.leilao.id,
        parametros: {
          empresa: this.empresa.cnpj
        }
      }
      this.$store.dispatch('leilaoVeiculos', data).then(() => {
        this.activeAlert('A disputa de lances foi desabilitada com sucesso.', 'success')
        this.slide_deletar = false
        this.tem_leilao = false
        this.leilao = {
          id: undefined,
          veiculo: undefined,
          data_inicio: undefined,
          data_fim: undefined,
          horario_inicio: undefined,
          horario_fim: undefined,
          status: 1
        }
      })
    },
    salvar () {
      const data = {
        method: this.leilao.id ? 'PUT' : 'POST',
        id: this.leilao.id,
        body: {
          ...this.leilao,
          empresa: this.empresa.cnpj,
          veiculo_leiloado: this.veiculo.id_veiculo,
          data_inicio: `${this.data_inicio} ${this.leilao.horario_inicio}:00.764902-03`,
          data_fim: `${this.date_encerramento} ${this.leilao.horario_fim}:00.764902-03`,
          valor_inicial: this.converterParaNumero(this.leilao.valor_inicial)
        }
        // Formato '2023-01-18 09:40:24.764902-03'
      }
      this.$store.dispatch('leilaoVeiculos', data).then(json => {
        this.activeAlert(
          'As configurações foram salvas com sucesso',
          'success'
        )
        this.formatarDadosLeilao(json)
        this.$emit('carregar')
      })
    },
    testarDataFim (data) {
      if (this.data_inicio) {
        return Date.parse(data) >= Date.parse(this.data_inicio)
      } else {
        return Date.parse(data) >= this.today
      }
    }
  }
}
</script>
