import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import { aesKey, iv } from '../keys.json'
import {
  termosDeUsoDao,
  pessoasDao,
  empresasDao,
  funcionariosDao,
  veiculoDao,
  combustivelDao,
  ofertasDao,
  tipospagamentoDao,
  propostaDao,
  comprasDao,
  classeveiculoDao,
  voucherDao,
  politicasDePrivacidadeDao,
  pagamentoDao,
  postoDao,
  precoCombustivelDao,
  creditoDao,
  redeDao,
  dashboardDao,
  relatorioDao,
  faturamentoDao,
  notificacoesDao,
  administradorDao,
  carteiraDigitalDao,
  tipoVeiculoDao,
  solicitacaoDao,
  meioRecebimentoDao,
  configuracoesApetrusDao,
  pagPixBankDao,
  paymentsDao,
  coordenadas,
  pagamentosExtraDao,
  itemDao,
  categoriaDao,
  codigosPromocionaisDao,
  clientesDao,
  premioDao,
  assinaturaDao,
  frentistaDao,
  bandeiraCartaoDao,
  provedorSMTPDao,
  FotoDao,
  leilaoDao,
  integracaoAutomaticaDao
} from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    headers: null,
    empresa: null,
    empresas: null,
    endereco: null,
    ofertas: [],
    oferta: null,
    ofertasPorPosto: [],
    pessoa: null,
    loading: false,
    error: null,
    success: null,
    lastPage: null,
    veiculos: [],
    veiculosPorFuncionario: [],
    frentistas: [],
    veiculo: null,
    tipos_pagamento: [],
    combustiveis: [],
    promocoesPorPosto: [],
    posto: null,
    funcionarios: [],
    funcionario: null,
    propostas: [],
    grupoVeiculos: [],
    vouchers: [],
    totalItemsPage: null,
    interval: 0,
    politicasDePrivacidade: null,
    termosDeUso: null,
    postos: [],
    creditos: [],
    recargargasSolicitadas: [],
    recarga: null,
    redes: {
      results: []
    },
    datagrafic: null,
    compras: [],
    hash: null,
    compras_no_posto: [],
    total_relatorio_compras_no_posto: null,
    total_relatorio_compras: null,
    total_relatorio_vouchers: null,
    cartoes: [],
    cartao: null,
    isNotPessoa: false,
    timer: 0,
    smartLock: false,
    hasNewVersion: false,
    versao: localStorage.getItem('apetrus_empresa-versao'),
    appVerifier: null,
    verificationId: null,
    qrcodeCobranca: null,
    estados: ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT',
      'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SP', 'SE', 'TO'
    ],
    pagamentosExtra: [],
    pagamentosSecundarios: [],
    formasPag: []
  },
  mutations: {
    setTermosDeUso (state, payload) {
      state.termosDeUso = payload
    },
    setFotoPerfil (state, payload) {
      state.fotoPerfil = payload
    },
    setAuthHeader (state, payload) {
      state.headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payload}`
      })
    },
    setIsNotPessoa (state, payload) {
      state.isNotPessoa = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setPosto (state, payload) {
      state.posto = payload
    },
    setUserImage (state, payload) {
      state.user.image = payload
    },
    setPessoa (state, payload) {
      state.pessoa = payload
    },
    setEmpresa (state, payload) {
      state.empresa = payload
    },
    setPropostas (state, payload) {
      state.propostas = payload
    },
    setEmpresas (state, payload) {
      state.empresas = payload || []
    },
    setVeiculos (state, payload) {
      state.veiculos = payload
    },
    setCreditos (state, payload) {
      state.creditos = payload
    },
    setRecargasSolicitadas (state, payload) {
      state.recargargasSolicitadas = payload
    },
    setRecarga (state, payload) {
      state.recarga = payload
    },
    setVeiculo (state, payload) {
      state.veiculo = payload
      localStorage.setItem(`a-${state.cliente.id_cliente}`, JSON.stringify(payload))
    },
    setVeiculoFuncionario (state, payload) {
      state.veiculosPorFuncionario = payload
    },
    setEndereco (state, payload) {
      state.endereco = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    setSuccess (state, payload) {
      state.success = payload
    },
    setHash (state, payload) {
      state.hash = payload
    },
    insertEmpresa (state, payload) {
      state.empresas.push(payload)
    },
    setCombustiveis (state, payload) {
      state.combustiveis = payload
    },
    setFuncionarios (state, payload) {
      state.funcionarios = payload
    },
    setFuncionario (state, payload) {
      state.funcionario = payload
    },
    aceitarTermos (state) {
      state.empresa = { ...state.empresa, termos_aceitos: true }
    },
    geolocateEmpresa (state, payload) {
      state.empresa = { ...state.empresa, latitude: payload.lat, longitude: payload.lng, geoposition: true }
    },
    setOfertas (state, payload) {
      state.ofertas = payload
    },
    setOferta (state, payload) {
      state.oferta = payload
    },
    setOfertasPorPosto (state, payload) {
      state.ofertasPorPosto = payload
    },
    setTiposPagamento (state, payload) {
      state.tipos_pagamento = payload
    },
    setPromocoesPorPosto (state, payload) {
      state.promocoesPorPosto = payload
    },
    setLastPage (state, payload) {
      if (state.user) {
        localStorage.setItem(`user-${state.user.id}.lastPage`, payload)
      }
      state.lastPage = payload
    },
    setGrupoVeiculos (state, payload) {
      state.grupoVeiculos = payload
    },
    setVouchers (state, payload) {
      state.vouchers = payload
    },
    setTotalItemsPage (state, payload) {
      state.totalItemsPage = payload
    },
    setPoliticasDePrivacidade (state, payload) {
      state.politicasDePrivacidade = payload
    },
    setPostos (state, payload) {
      state.postos = payload
    },
    setRedes (state, payload) {
      state.redes = payload
    },
    setDataGrafic (state, payload) {
      state.datagrafic = payload
    },
    setCompras (state, payload) {
      state.compras = payload
    },
    setTotalRelatorioCompras (state, payload) {
      state.total_relatorio_compras = payload
    },
    setTotalRelatorioComprasNoPosto (state, payload) {
      state.total_relatorio_compras_no_posto = payload
    },
    setComprasNoPosto (state, payload) {
      state.compras_no_posto = payload
    },
    setTotalRelatorioVouchers (state, payload) {
      state.total_relatorio_vouchers = payload
    },
    setCartao (state, payload) {
      state.cartao = payload
      if (payload) {
        localStorage.setItem(`user-${state.user.id}.cartao`, payload.id_cartao.toString() + '-' + payload.hash_code)
      } else {
        localStorage.setItem(`user-${state.user.id}.cartao`, undefined)
      }
    },
    setDesativarCartao (state, payload) {
      state.cartao = payload
      localStorage.setItem(`user-${state.user.id}.cartao`, null)
    },
    setCartoes (state) {
      state.cartoes.push(state.cartao)
    },
    addCartao (state, payload) {
      state.cartao.numero_cartao = payload.numero_cartao
      state.cartao.credito = payload.credito
      state.cartao.nome = payload.titular.nome
      state.cartao.verificado = payload.verificado
    },
    setHasNewVersion (state, payload) {
      state.hasNewVersion = payload
    },
    setVersao (state, payload) {
      state.versao = payload
    },
    clearTimer (state) {
      state.smartLock = false
      state.timer = 0
    },
    decreaseTimer (state, payload) {
      state.timer -= payload
      if (state.timer <= 0) {
        state.timer = 0
        state.smartLock = false
      }
    },
    setTimer (state, payload) {
      state.smartLock = true
      state.timer = payload
    },
    setAppVerifier (state, payload) {
      state.appVerifier = payload
    },
    setVerificationId (state, payload) {
      state.verificationId = payload
    },
    setQrcodeCobranca (state, payload) {
      state.qrcodeCobranca = payload
    },
    setPagamentosExtra (state, payload) {
      state.pagamentosExtra = payload
    },
    setPagamentosSecundarios (state, payload) {
      state.pagamentosSecundarios = payload
    },
    setFormasPag (state, payload) {
      state.formasPag = payload
    },
    setTotalItemsFormasPag (state, payload) {
      state.totalItemsFormasPag = payload
    },
    changeFrentistas (state, frentista) {
      state.frentistas = [
        ...state.frentistas.map(item => {
          return item.id_frentista !== frentista.id_frentista ? item : frentista
        })
      ]
    },
    setFrentistas (state, payload) {
      state.frentistas = payload
    },
    addFrentista (state, payload) {
      state.frentistas.push(payload)
    }
  },
  actions: {
    restoreTimer ({
      commit,
      state
    }) {
      if (state.smartLock) {
        commit('setTimer', 60 * 60)
      }
    },
    addTimer ({
      commit,
      state
    }) {
      if (!state.smartLock) {
        commit('setTimer', 60 * 60)
        var interval = setInterval(() => {
          if (state.timer > 0) {
            commit('decreaseTimer', 1)
          } else {
            clearInterval(interval)
          }
        }, 1000)
      }
    },
    carregarTermosDeUso ({
      commit,
      state
    }) {
      commit('setLoading', true)
      termosDeUsoDao.get().then(response => {
        commit('setLoading', false)
        if (response.ok) {
          return response.json()
        } else {
          throw Error('Desculpe, não foi possível carregar os termos de uso')
        }
      }).then(json => {
        commit('setTermosDeUso', json)
      }).catch(() => {
        commit('setError', 'Algo deu errado tente novamente mais tarde')
      })
    },
    carregarTermosDeUsoCliente ({
      commit,
      state
    }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        termosDeUsoDao.getTermoCliente().then(response => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            throw Error('Desculpe, não foi possível carregar os termos de uso do usuário')
          }
        }).then(json => {
          resolve(json)
        }).catch(() => {
          reject(new Error('Não foi possível pegar termos do cliente.'))
          commit('setError', 'Algo deu errado tente novamente mais tarde')
        })
      })
    },
    atualizarFotoPerfil ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      const storageRef = firebase.storage().ref()
      const imageRef = storageRef.child(`users/avatars/${payload.id}.png`)
      imageRef.putString(payload.imagem, 'data_url').then(snapshot => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
          const imagem = {
            url_imagem_perfil: downloadURL
          }
          commit('setUserImage', downloadURL)
          pessoasDao.atualizarImagemPerfil(imagem, state.headers)
            .then(res => {
              commit('setLoading', false)
              if (res.ok) {
                commit('setFotoPerfil', imagem.url_imagem_perfil)
                this.dialogConf = true
              } else {
                commit('setError', 'Não foi possível salvar a imagem de perfil')
                this.dialogImagem = true
              }
            })
        })
      })
    },
    setError ({ commit }, payload) {
      commit('setError', payload)
    },
    setSuccess ({ commit }, payload) {
      commit('setSuccess', payload)
    },
    selecionarFuncionario ({ commit }, payload) {
      commit('setFuncionario', payload)
    },
    aceitarTermos ({ commit }, payload) {
      commit('aceitarTermos', payload)
    },
    geoposicionarEmpresa ({ commit }, payload) {
      commit('geolocateEmpresa', payload)
    },
    carregarVeiculos ({ commit, state }, payload) {
      commit('setLoading', true)
      veiculoDao.getVeiculosByEmpresa(state.headers, payload).then(response => {
        commit('setLoading', false)
        if (response.ok) {
          return response.json()
        }
      }).then(json => {
        commit('setTotalItemsPage', json.count)
        commit('setVeiculos', json.results)
        if (localStorage.getItem(`a-${state.empresa.cnpj}`)) {
          const veiculo = JSON.parse(localStorage.getItem(`a-${state.empresa.cnpj}`))
          if (veiculo) {
            const index = state.veiculos.findIndex(v => v.id_veiculo === veiculo.id_veiculo)
            if (index !== -1) {
              commit('setVeiculo', state.veiculos[index])
            } else {
              commit('setVeiculo', null)
            }
          }
        }
      })
    },
    carregarVeiculosporClasse ({ commit, state }, payload) {
      commit('setLoading', true)
      veiculoDao.getVeiculosPorClasse(state.headers, payload).then(response => {
        commit('setLoading', false)
        if (response.ok) {
          return response.json()
        }
      }).then(json => {
        commit('setTotalItemsPage', json.count)
        commit('setVeiculos', json.results)
        if (localStorage.getItem(`a-${state.empresa.cnpj}`)) {
          const veiculo = JSON.parse(localStorage.getItem(`a-${state.empresa.cnpj}`))
          if (veiculo) {
            const index = state.veiculos.findIndex(v => v.id_veiculo === veiculo.id_veiculo)
            if (index !== -1) {
              commit('setVeiculo', state.veiculos[index])
            } else {
              commit('setVeiculo', null)
            }
          }
        }
      })
    },
    carregarVoucherVeiculos ({ commit, state }, payload) { // não usa paginação
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        veiculoDao.getVeiculosByEmpresaVoucher(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          }
          reject(Error('Não foi possível pegar veículos'))
        }).then(json => {
          commit('setVeiculos', json)
          if (localStorage.getItem(`a-${state.empresa.cnpj}`)) {
            const veiculo = JSON.parse(localStorage.getItem(`a-${state.empresa.cnpj}`))
            if (veiculo) {
              const index = state.veiculos.findIndex(v => v.id_veiculo === veiculo.id_veiculo)
              if (index !== -1) {
                commit('setVeiculo', state.veiculos[index])
              } else {
                commit('setVeiculo', null)
              }
            }
          }
          resolve(json)
        })
      })
    },
    veiculoPorFuncionario ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        veiculoDao.getVeiculosPorFuncionario(state.headers, payload.cpf, payload.cnpj)
          .then(response => {
            commit('setLoading', false)
            if (response.ok) {
              return response.json()
            } else {
              if (response.status === 422) {
                commit('setVeiculoFuncionario', null)
                // commit('setError', { message: 'O funcionario não tem veiculo.' })
              }
              reject(Error('Não foi possível pegar veículos'))
            }
          }).then(json => {
            commit('setLoading', false)
            commit('setVeiculoFuncionario', json)
            resolve(json)
          })
      })
    },
    async renovarToken ({ commit, state }) {
      let diference
      var expirationTime
      var issuedAtTime
      if (state.user != null) {
        await firebase
          .auth()
          .currentUser.getIdTokenResult(true)
          .then((result) => {
            expirationTime = Date.parse(result.expirationTime)
            issuedAtTime = Date.parse(result.issuedAtTime)
            diference = (expirationTime - issuedAtTime) / 1000 / 2
            diference = diference.toFixed(0)
          })
        state.interval = setInterval(() => {
          diference--
          if (diference === 0) {
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then((token) => {
                commit('setAuthHeader', token)
              })
            firebase
              .auth()
              .currentUser.getIdTokenResult(true)
              .then((result) => {
                var expirationTime
                var issuedAtTime
                expirationTime = Date.parse(result.expirationTime)
                issuedAtTime = Date.parse(result.issuedAtTime)
                diference = (expirationTime - issuedAtTime) / 1000 / 2
                diference = diference.toFixed(0)
              })
          }
        }, 1000)
      }
    },
    userChanged ({ commit }, payload) {
      const user = payload
      commit('setLoading', false)
      if (user) {
        const newUser = {
          id: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          image: null,
          events: []
        }

        user.getIdToken().then(
          token => {
            commit('setUser', newUser)
            commit('setAuthHeader', token)
            this.dispatch('renovarToken')

            const storage = firebase.storage()
            storage.ref(`users/avatars/${user.uid}.png`).getDownloadURL()
              .then(
                imageUrl => {
                  commit('setUserImage', imageUrl)
                }
              ).catch(
                error => {
                  console.log('erro', error)
                }
              )
            this.dispatch('carregarPerfil')
            const lastpage = localStorage.getItem(`user-${newUser.id}.lastPage`)
            if (lastpage) {
              commit('setLastPage', lastpage)
            }
          }
        )
      } else {
        commit('setUser', null)
        // commit('setAuthHeader', null)
        commit('setLastPage', null)
      }
      // incluir os demais atributos que o usuário pode ter
      // Como postos que administra, perfil de cliente e perfil de frentista
    },
    carregarTiposPagamento ({ commit }) {
      tipospagamentoDao.get().then(response => {
        return response.json()
      }).then(json => {
        commit('setTiposPagamento', json)
      })
    },
    realizarCompra ({ commit, state }, payload) {
      this.dispatch('renovarToken')
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        comprasDao.realizarCompra(payload, state.headers).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            if (response.status === 403) {
              throw Error('Desculpe, a oferta está indisponível no momento')
            } else if (response.status === 422) {
              throw Error('Desculpe, essa oferta não possui a quantidade de combustível requisitada')
            } else {
              throw Error('Erro, verifique as informações e tente novamente')
            }
          }
        }).then((json) => {
          resolve(json)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    finalizarCompra ({ commit, state }, payload) {
      this.dispatch('renovarToken')
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        comprasDao.pagar(payload, state.headers).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            if (response.status === 403) {
              throw Error('Desculpe, a oferta está indisponível no momento')
            } else if (response.status === 422) {
              throw Error('Desculpe, essa oferta não possui a quantidade de combustível requisitada')
            } else {
              throw Error('Erro, verifique as informações e tente novamente')
            }
          }
        }).then((json) => {
          resolve(json)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    carregarVouchers ({ commit, state }, payload) {
      voucherDao.vouchersPorEmpresa(state.headers, payload).then(response => {
        if (response.ok) {
          return response.json()
        }
      }).then(json => {
        commit('setTotalItemsPage', json.count)
        commit('setVouchers', json.results)
      })
    },
    carregarCreditos ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        creditoDao.meusCreditos(state.headers, payload)
          .then(res => res.json())
          .then(json => {
            commit('setTotalItemsPage', json.count)
            commit('setCreditos', json)
            resolve(json)
          }).catch(error => reject(error))
      })
    },
    criarCredito ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        creditoDao.adicionarCredito(payload, state.headers).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            commit('setSuccess', 'Crédito criado com sucesso.')
            return resolve(response.json())
          } else {
            response.json().then(result => {
              commit('setError', result.error)
              reject(new Error(`${result.error}`))
            })
          }
        })
      })
    },
    solicitarAumentoSaldo ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        propostaDao.solicitarRecargaProposta(payload, state.headers).then(response => {
          if (response.ok) {
            response.json().then(result => {
              commit('setRecarga', result)
              resolve('ok')
            })
          } else {
            response.json().then(result => {
              commit('setError', result.error)
              reject(new Error('Não foi possível fazer acréscimo.'))
            })
          }
        })
      })
    },
    carregarCreditosSolicitados ({ commit, state }, payload) {
      commit('setLoading', true)
      commit('setTotalItemsPage', [])
      commit('setRecargasSolicitadas', [])
      return new Promise((resolve, reject) => {
        propostaDao.meusCreditosSolicitados(state.headers, payload).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          commit('setTotalItemsPage', json.count)
          commit('setRecargasSolicitadas', json.results)
          resolve('ok')
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    carregarCombustiveis ({ commit, state }) {
      combustivelDao.getCombustiveis(state.headers).then(response => {
        if (response.ok) {
          return response.json()
        }
      }).then(json => {
        commit('setCombustiveis', json)
      })
    },
    signUserIn ({ commit }, payload) {
      commit('setLoading', true)
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .catch(
          error => {
            commit('setLoading', false)
            const errorMessage = { message: 'Dados Incorretos! Não foi possível realizar login' }
            commit('setError', errorMessage)
            console.log(error)
          }
        )
    },
    signUserOut ({ commit, state }) {
      commit('setLoading', true)
      if (state.cartao) {
        this.dispatch('apagarDadosCartao')
      }
      firebase.auth().signOut()
        .catch((error) => {
          var errorCode = error.code
          var errorMessage = error.message
          console.log(errorCode)
          console.log(errorMessage)
        })
      clearInterval(state.interval)
    },
    sendVerifyEmail ({
      commit
    }) {
      commit('setLoading', true)
      return new Promise((resolve) => {
        firebase.auth().currentUser.sendEmailVerification().then(response => {
          commit('setLoading', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoading', false)
          commit('setError', error)
        })
      })
    },
    unselectEmpresa ({ commit, state }) {
      commit('setEmpresa', null)
      commit('setEndereco', null)
      localStorage.removeItem(`user-${state.user.id}.empresa`)
    },
    escolherEmpresa ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload) {
          commit('setEmpresa', payload)
          commit('setEndereco', null)
          resolve()
        } else {
          commit('setLoading', true)
          empresasDao.detalharEmpresa(payload.cnpj, state.headers).then(response => {
            commit('setLoading', false)
            if (!response.ok) {
              reject(new Error('Não foi possível detalhar o posto'))
            }
            return response.json()
          }).then(json => {
            commit('setEmpresa', json)
            if (json.endereco) commit('setEndereco', json.endereco)
            localStorage.setItem(`user-${state.user.id}.empresa`, payload.cnpj)
            resolve()
          })
        }
      })
    },
    minhasEmpresas ({ commit, state }) {
      commit('setLoading', true)
      empresasDao.minhasEmpresas(state.headers).then((response) => {
        commit('setLoading', false)
        return response.json()
      }).then((json) => {
        let empresa = null
        if (json.length === 1) {
          empresa = json[0]
        } else {
          const cnpjEmpresa = localStorage.getItem(`user-${state.user.id}.empresa`)
          if (cnpjEmpresa) {
            empresa = json.find((e) => {
              return e.cnpj === cnpjEmpresa
            })
          }
        }
        this.dispatch('escolherEmpresa', empresa).then(() => {
          commit('setEmpresas', json)
        })
      })
    },
    carregarEmpresas ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.minhasEmpresas(state.headers, payload).then((response) => {
          return response.json()
        }).then((json) => {
          commit('setEmpresas', payload.page ? json.result : json || [])
          commit('setLoading', false)
          resolve(json)
        }).catch(() => {
          commit('setLoading', false)
          reject(Error('Não foi possível pegar empresas'))
        })
      })
    },
    carregarEmpresasComPaginacao ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.minhasEmpresas(state.headers, payload).then((response) => {
          return response.json()
        }).then((json) => {
          commit('setLoading', false)
          resolve(json)
        }).catch(() => {
          commit('setLoading', false)
          reject(Error('Não foi possível pegar empresas'))
        })
      })
    },
    cadastroEmpresaByClient ({ commit, state }, payload) {
      commit('setLoading', true)
      empresasDao.inserirByClient(payload.empresa, state.headers).then((response) => {
        commit('setLoading', false)
        if (!response.ok) {
          if (response.status === 422) {
            commit('setError', 'Não possível cadastrar sua empresa.')
          }
        } else {
          payload.empresa.cnpj = payload.empresa.cnpj.replace(/[^\d]+/g, '')
          commit('insertEmpresa', payload.empresa)
          localStorage.setItem(`user-${state.user.id}.empresa`, payload.empresa.cnpj)
          this.dispatch('minhasEmpresas')
        }
      })
    },
    toogle24horas ({
      state
    }) {
      return new Promise((resolve, reject) => {
        if (!state.empresa || (state.empresa && !state.empresa.ativo)) {
          reject(new Error('Essa empresa está desativada no sistema'))
          return
        }
        empresasDao.update24horas(state.empresa.cnpj, state.headers).then((response) => {
          if (!response.ok) {
            reject(new Error(response.text))
          }
          return response.json()
        })
      })
    },
    InserirNovaEmpresa ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.inserirByClient(payload.empresa, state.headers).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Não possível cadastrar sua empresa.')
            reject(new Error('Não possível cadastrar sua empresa.'))
          } else {
            resolve('ok')
          }
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    carregarPerfil ({ commit, state }) {
      commit('setLoading', true)
      pessoasDao.auth(state.headers).then(response => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 404) {
          commit('setIsNotPessoa', true)
        }
      }).then(json => {
        commit('setLoading', false)
        commit('setPessoa', json)
      }).catch(() => {
        commit('setLoading', false)
        console.log('Usuário não encontrado')
      })
    },
    cadastroPessoa ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pessoasDao.insert(payload.cadUser, state.headers).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Desculpe, esse CPF já está sendo usado por outro usuário.')
            reject(new Error('Desculpe, esse CPF já está sendo usado por outro usuário. '))
          } else {
            this.step++
            return response.json()
          }
        }).then(json => {
          commit('setPessoa', json)
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          commit('setError', 'Houve um erro na realização do seu cadastro')
          reject(error)
        })
      })
    },
    apagarVeiculo ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        veiculoDao.deleteVeiculoByEmpresa(payload, state.headers)
          .then(response => {
            if (response.ok) {
              commit('setSuccess', 'Veículo excluído com sucesso!')
              resolve('ok')
            } else reject(new Error('Não foi possível apagar veículo'))
          })
      })
    },
    confirmarVisualizacaoVeiculo ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        veiculoDao.confirmarVisualizacaoVeiculo(state.headers, payload)
          .then(response => {
            if (response.ok) {
              resolve('ok')
            } else reject(new Error('Ocorreu um erro'))
          })
      })
    },
    tornarCliente ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pessoasDao
          .insertClient(state.headers)
          .then(response => {
            commit('setLoading', false)
            if (!response.ok) {
              resolve('ok!')
              // reject(new Error('Não foi possível adicionar como cliente. '))
            } else {
              return response.json()
            }
          })
          .then(json => {
            resolve(json)
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            reject(error)
          })
      })
    },
    getFuncionariosEmpresaConfirmadosSemPaginacao ({ commit, state }, cnpj) {
      return new Promise((resolve, reject) => {
        funcionariosDao.getFuncionariosEmpresaConfirmadosSemPaginacao(cnpj, state.headers).then(response => {
          if (response.ok) {
            resolve(response.json())
          }
        })
      })
    },
    ativarOuDesativarFuncionario ({ commit, state }, payload) {
      commit('setLoading', true)
      funcionariosDao
        .ativarOuDesativarFuncionario(payload, state.headers)
        .then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            return true
          } else {
            return response.error()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    carregarFuncionarios ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        funcionariosDao.getFuncionariosEmpresa(state.headers, payload)
          .then(res => res.json())
          .then(json => {
            commit('setTotalItemsPage', json.count)
            commit('setFuncionarios', json.results)
            resolve(json)
          }).catch(error => reject(error))
      })
    },
    pegarFuncionarios ({ commit, state }) {
      return new Promise((resolve, reject) => {
        funcionariosDao.getFuncionariosSemPaginacao(state.empresa.cnpj, state.headers)
          .then(res => res.json())
          .then(json => {
            resolve(json)
          }).catch(error => reject(error))
      })
    },
    insertClasseDeVeiculo ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        var data = {
          descricaoGrupo: payload,
          empresa: state.empresa.cnpj
        }
        classeveiculoDao.insertClasseVeiculo(data, state.headers)
          .then(response => {
            if (response.ok) {
              this.dispatch('getClassesporEmpresa')
              this.dispatch('setSuccess', 'Grupo cadastrado com sucesso')
              resolve('Ok')
            }
            this.dispatch('setError', 'Não foi possível cadastrar grupo')
            reject(new Error('Não foi possível cadastrar grupo'))
          })
      })
    },
    getClassesporEmpresa ({ commit, state }) {
      classeveiculoDao.getClassesporEmpresa(state.empresa.cnpj, state.headers)
        .then(res => res.json())
        .then(json => {
          commit('setGrupoVeiculos', json)
        })
    },
    removerFuncionario ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        funcionariosDao.removerFuncionario(state.headers, payload.funcionario).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(Error('Não foi possível remover funcionarios'))
          }
          resolve('OK')
        })
      })
    },
    inserirVeiculoNoGrupo ({ commit, state }, payload) {
      classeveiculoDao.inserirVeiculoNoGrupo(payload, state.headers).then((response) => {
        if (response.ok) {
          this.dispatch('carregarVeiculos', payload.pagination)
        }
      })
    },
    removerVeiculoDoGrupo ({ commit, state }, payload) {
      classeveiculoDao.removerVeiculoDoGrupo(payload, state.headers).then((response) => {
        if (response.ok) {
          this.dispatch('carregarVeiculos', payload.pagination)
        }
      })
    },
    inserirMotorista ({ commit, state }, payload) {
      var data = {
        id_funcionario: payload.id_funcionario,
        id_veiculo: payload.veiculo.id_veiculo
      }
      veiculoDao.setMotorista(data, state.headers).then((response) => {
        if (!response.ok) {
          if (response.status === 422) {
            console.log('setError', { message: 'Funcionario não encontrado.' })
          }
          if (response.status === 403) {
            console.log('setError', { message: 'veiculo não encontrado.' })
          }
        } else {
          commit('setVeiculoFuncionario', payload.veiculo)
          // var dataMotorista = {
          //   cnpj: state.empresa.cnpj,
          //   cpf: state.pessoa.cpf
          // }
          // this.dispatch('veiculoPorFuncionario', dataMotorista)
          this.dispatch('carregarVoucherVeiculos', state.empresa.cnpj, payload.pagination)
        }
      })
    },
    removerMotorista ({ commit, state }, payload) {
      veiculoDao.removeMotorista(payload, state.headers).then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            console.log('setError', { message: 'veiculo não encontrado.' })
          }
        } else {
          this.dispatch('carregarVeiculos', payload.pagination)
          commit('setVeiculoFuncionario', null)
        }
      })
    },
    buscarPropostas ({ commit, state }, payload) {
      if (!state.empresa) {
        return undefined
      }
      return new Promise((resolve, reject) => {
        propostaDao.buscarPropostas(state.headers, state.empresa.cnpj, payload.pagination, payload.filtros)
          .then(res => res.json())
          .then(json => {
            commit('setPropostas', json)
            resolve(json)
          })
      })
    },
    lancarProposta ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        propostaDao.insert(payload, state.headers).then(response => {
          response.json().then(value => {
            if (!response.ok) {
              commit('setError', value.mensagem)
              reject(new Error(value.mensagem))
            } else {
              commit('setSuccess', value.mensagem)
              resolve('ok')
            }
          })
        })
      })
    },
    cadastroFuncionario ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pessoasDao.atualizar(payload.cadUser, state.headers).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            if (response.status === 422) {
              throw Error('Desculpe, já possuímos um usuário cadastrado com esse CPF')
            } else {
              throw Error('Falha ao cadastrar pessoa')
            }
          } else {
            // this.step++
            return response.json()
          }
        }).then(json => {
          commit('setPessoa', json)
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          commit('setError', 'Não foi possível cadastrar funcionario')
          reject(error)
        })
      })
    },
    iniciarVerificacao ({ commit, state }, payload) {
      this.dispatch('renovarToken')
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        comprasDao
          .iniciarVerificacao(payload.id, payload.hash, payload.cartao, state.headers)
          .then(response => {
            if (response.ok) {
              commit('setLoading', false)
            } else {
              if (response.status === 403) {
                throw Error('Desculpe, erro ao iniciar verificação')
              } else if (response.status === 422) {
                throw Error('Desculpe, erro ao iniciar verificação')
              } else {
                throw Error('Erro, verifique as informações e tente novamente')
              }
            }
          })
          .then(json => {
            commit('setSuccess', 'Um pequeno valor foi cobrado de seu cartão, clique em "verificar cartão" no menu lateral e conclua o processo de verificação para ter o valor estornado.')
            resolve(json)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // inserirFuncionario ({ commit, state }, payload) {
    //   commit('setLoading', true)
    //   funcionariosDao.inserir(this.funcionarios, this.headers).then(res => {

    //   })
    // },
    atualizarEnderecoPessoa ({ commit, state }, payload) {
      commit('setLoading', true)
      pessoasDao.atualizarEndereco(payload.enderecoPessoa, state.headers).then(response => {
        commit('setLoading', false)
        if (response.ok) {
        } else {
          commit('setError', { message: 'Não foi possível atualizar o endereço do usuário' })
        }
      })
    },
    atualizarFuncionario ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        funcionariosDao.atualizarColaborador(payload, state.headers).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            commit('setSuccess', 'Funcionário editado com sucesso!')
          } else {
            commit('setError', { message: 'Não foi possível atualizar o funcionário' })
          }
        })
      })
    },
    sendContactMail ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        funcionariosDao.contact(payload, state.headers).then(function (response) {
          commit('setLoading', false)
          if (response.ok) {
            resolve('ok')
          } else {
            reject(new Error('Não foi possível enviar o email!'))
          }
        })
      })
    },
    signUserUp ({ commit }, payload) {
      commit('setLoading', true)
      firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
        .then(
          (userCred) => {
            const user = userCred.user
            const newUser = {
              id: user.uid,
              email: user.email,
              emailVerified: user.emailVerified,
              events: []
            }
            user.getIdToken(true).then(
              token => {
                commit('setUser', newUser)
                commit('setAuthHeader', token)
              }
            )

            firebase.auth().currentUser.sendEmailVerification().then(() => {
              commit('setLoading', false)
            }).catch((error) => {
              commit('setLoading', false)
              commit('setError', 'Algo deu errado tente novamente mais tarde.')
              console.log(error)
            })

            // incluir os demais atributos que o usuário pode ter
            // Como postos que administra, perfil de cliente e perfil de frentista
          }
        )
        .catch(
          error => {
            commit('setLoading', false)
            commit('setError', 'Algo deu errado tente novamente mais tarde.')
            console.log(error)
          }
        )
    },
    carregarPoliticasDePrivacidade ({
      commit,
      state
    }) {
      commit('setLoading', true)
      politicasDePrivacidadeDao.get().then(response => {
        commit('setLoading', false)
        if (response.ok) {
          return response.json()
        } else {
          throw Error('Desculpe, não foi possível carregar as políticas de privacidade')
        }
      }).then(json => {
        commit('setPoliticasDePrivacidade', json)
      }).catch(() => {
        commit('setError', 'Não foi possível carregar politica')
      })
    },
    sendResetEmail ({
      commit
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        firebase.auth().sendPasswordResetEmail(payload).then((response) => {
          commit('setLoading', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    inserirArquivoFirebase ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref()
        const imageRef = storageRef.child(`negociacao/${state.empresa.cnpj}/${payload.local}/${payload.prefixo}_${payload.id}.${payload.tipo}`)
        imageRef.putString(payload.arquivo, 'data_url').then(snapshot => {
          commit('setLoading', false)
          snapshot.ref.getDownloadURL().then(downloadURL => {
            resolve(downloadURL)
          }).catch(error => {
            reject(error)
          })
        })
      })
    },
    // Não mexer
    inserirFirebase ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref()
        const imageRef = storageRef.child(`empresas/${payload.id}/${payload.local}/${payload.id_aux ? payload.id_aux : ''}${payload.prefixo}_${payload.identificacao}.${payload.tipo}`)
        imageRef.putString(payload.arquivo, 'data_url').then(snapshot => {
          commit('setLoading', false)
          snapshot.ref.getDownloadURL().then(downloadURL => {
            resolve(downloadURL)
          }).catch(error => {
            reject(error)
          })
        })
      })
    },
    deletarArquivoFirebase ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().refFromURL(payload).delete()
        storageRef.then(() => {
          resolve('Arquivo deletado com sucesso!')
        }).catch((error) => {
          reject(error)
        })
      })
    },
    inserirComprovante ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        propostaDao.inserirComprovante(payload, state.headers).then(response => {
          if (!response.ok) {
            reject(new Error('Não foi possível atualizar a proposta'))
          }
          commit('setSuccess', 'Comprovante inserido com sucesso')
          resolve('ok')
        })
      })
    },
    inserirArquivoFirebaseRecarga ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref()
        const imageRef = storageRef.child(`recarga_proposta/${state.empresa.cnpj}/${payload.local}/${payload.prefixo}_${payload.id}.${payload.tipo}`)
        imageRef.putString(payload.arquivo, 'data_url').then(snapshot => {
          commit('setLoading', false)
          snapshot.ref.getDownloadURL().then(downloadURL => {
            resolve(downloadURL)
          }).catch(error => {
            reject(error)
          })
        })
      })
    },
    inserirComprovanteRecarga ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        propostaDao.comprovanteRecargaProposta(payload, state.headers).then(response => {
          if (!response.ok) {
            reject(new Error('Não foi possível atualizara recarga'))
          }
          commit('setSuccess', 'Comprovante inserido com sucesso')
          resolve('ok')
        })
      })
    },
    pegarPostosMapa ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        postoDao.pegarPostosMapa(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    pegarPostos ({ commit, state }, payload) {
      commit('setLoading', true)
      commit('setPostos', [])
      return new Promise((resolve, reject) => {
        postoDao.filtroDePostos(state.headers, payload).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          commit('setPostos', json)
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    pegarPosto ({ commit, state }, cnpj) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        postoDao.pegarPosto(state.headers, cnpj).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    pegarEndereco ({ commit, state }, cnpj) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        postoDao.pegarEndereco(state.headers, cnpj).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    pegarQuestionario ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pessoasDao.pegarQuestionario(payload, state.headers).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(new Error('Não foi possível realizar a operação.'))
          } else {
            resolve(response.json())
          }
        })
      })
    },
    enviarQuestionario ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        pessoasDao.enviarQuestionario(payload, state.headers).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Questionário inválido.')
            this.dispatch('carregarPerfil')
            reject(new Error('Não foi possível enviar o questionário.'))
          } else {
            commit('setSuccess', 'Questionário enviado')
            this.dispatch('carregarPerfil')
            resolve(response.json())
          }
        })
      })
    },
    precoCombustivelPorPosto ({ commit, state }, cnpj) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        precoCombustivelDao.pegarPorPosto(state.headers, cnpj).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    responderProposta ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        propostaDao.aceitarProposta(payload, state.headers).then(res => {
          if (res.ok) {
            commit('setSuccess', 'Proposta respondida com sucesso')
            resolve('ok')
          }
        }).catch(() => {
          commit('setError', 'Não foi possível responder proposta')
          reject(new Error('Não foi possível enviar resposta.'))
        })
      })
    },
    removerPermissaoFuncionario ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        creditoDao.removerCredito(payload, state.headers).then(res => {
          if (res.ok) {
            commit('setSuccess', 'Permissão retirada')
            resolve('ok')
          }
        }).catch(() => {
          commit('setError', 'Não foi possível retirar a permssão')
          reject(new Error('Não foi possível retirar a permssão'))
        })
      })
    },
    aumentarCreditoFuncionario ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        creditoDao.aumentarCredito(payload, state.headers).then(res => {
          if (res.ok) {
            commit('setSuccess', 'Crédito aumentado')
            resolve('ok')
          }
        }).catch(() => {
          commit('setError', 'Não foi possível aumentar o crédito!')
          reject(new Error('Não foi possível aumentar o crédito!'))
        })
      })
    },
    reduzirCreditoFuncionar ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        creditoDao.reduzirCredito(payload, state.headers).then(res => {
          if (res.ok) {
            commit('setSuccess', 'Crédito reduzido')
            resolve('ok')
          }
        }).catch(() => {
          commit('setError', 'Não foi possível reduzir o crédito!')
          reject(new Error('Não foi possível reduzir o crédito!'))
        })
      })
    },
    temProposta ({ state, commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        propostaDao.temProposta(payload.cnpj_empresa, payload.cnpj_posto, payload.id_rede, state.headers).then(res => {
          if (res.ok) {
            resolve('ok')
          }
        }).catch(() => {
          reject(new Error('Não foi possível encontrar resposta.'))
        })
      })
    },
    pegarRedes ({ state, commit }, payload) {
      commit('setLoading', true)
      commit('setRedes', [])
      return new Promise((resolve, reject) => {
        redeDao.filtrodeRedes(state.headers, payload).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          commit('setRedes', json)
          resolve('ok')
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    pegarPostosDaRede ({ commit, state }, id) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        redeDao.pegarPostosDaRede(state.headers, id).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    atualizarImagemEmpresa ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        empresasDao.atualizarImagem(payload.cnpj, payload, state.headers).then(response => {
          if (!response.ok) {
            commit('setError', 'Não foi possível atualizar imagem')
            reject(new Error('Não foi possível atualizar imagem'))
          } else resolve('ok')
        })
      })
    },
    atualizarUsuario ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        pessoasDao.atualizarUsuario(payload, state.headers).then((response) => {
          if (!response.ok) {
            commit('setError', 'Não foi possível realizar operação.')
            reject(new Error('Não foi possível realizar operação.'))
          } else {
            commit('setSuccess', 'Perfil atualizado com sucesso.')
            this.dispatch('carregarPerfil')
            resolve('ok')
          }
        })
      })
    },
    custoPorPosto ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        dashboardDao.custoPorPosto(state.headers, payload).then((response) => {
          return response.json()
          // resolve(response.json())
        }).then(json => {
          commit('setDataGrafic', json)
          // resolve('ok')
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    relatorioComprasCallback ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        relatorioDao.relatorioCompras(payload, state.empresa.cnpj, state.headers).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          resolve(json.compras)
        }).catch(() => {
          reject(new Error('Não foi possível carregar o relatório de compras'))
        })
      })
    },
    relatorioCompras ({
      commit,
      state
    }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        relatorioDao.relatorioCompras(payload, state.empresa.cnpj, state.headers).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          commit('setCompras', json.compras)
          commit('setTotalRelatorioCompras', json.valor_total)
          resolve(json)
        }).catch(() => {
          reject(new Error('Não foi possível carregar o relatório de compras'))
        })
      })
    },
    relatorioComprasPorPosto ({
      commit,
      state
    }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        relatorioDao.relatorioComprasPorPosto(payload, state.empresa.cnpj, state.headers).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          commit('setCompras', json.compras)
          commit('setTotalRelatorioCompras', json.valor_total)
          resolve(json)
        }).catch(() => {
          reject(new Error('Não foi possível carregar o relatório de compras'))
        })
      })
    },
    relatorioVouchers ({
      commit,
      state
    }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        relatorioDao.relatorioVouchers(payload.filtros, state.empresa.cnpj, state.headers, payload.pagination).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(Error('Não foi possível carregar relatorio'))
          }
          console.log(response)
          return response.json()
        }).then(json => {
          commit('setVouchers', json.vouchers)
          commit('setTotalRelatorioVouchers', json.valor_total)
          resolve(json)
        })
      })
    },
    relatorioFrota ({
      commit,
      state
    }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        relatorioDao.relatorioVeiculos(payload, state.empresa.cnpj, state.headers).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          commit('setVouchers', json.vouchers)
          commit('setTotalRelatorioVouchers', json.valor_total)
          resolve(json)
        }).catch(() => {
          reject(new Error('Não foi possível carregar o relatório de compras'))
        })
      })
    },
    relatorioColaborador ({
      commit,
      state
    }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        relatorioDao.relatorioFuncionarios(payload, state.empresa.cnpj, state.headers).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          commit('setVouchers', json.vouchers)
          commit('setTotalRelatorioVouchers', json.valor_total)
          resolve(json)
        }).catch(() => {
          reject(new Error('Não foi possível carregar o relatório de compras'))
        })
      })
    },
    relatorioVouchersCallback ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        if (!state.posto.ativo) {
          reject(new Error('Esse posto está desativado no sistema'))
          return
        }
        postoDao.relatorioVouchers(payload, state.posto.cnpj, state.headers).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          resolve(json.vouchers)
        }).catch(() => {
          reject(new Error('Não foi possível carregar o relatório de vouchers'))
        })
      })
    },
    confirmarCartao ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentoDao.validarCartao(payload.id, payload.data, state.headers).then(response => {
          if (response.ok) {
            resolve('ok')
          }
        }).then(() => {
          commit('setLoading', false)
          // commit('editCartoes', payload)
        }).catch(() => {
          reject(new Error('Aguarde um instante, em caso de erro na verificação tente novamente!'))
        })
      })
    },
    cadastarCartao ({
      commit,
      state
    }, payload) {
      var CryptoJS = require('crypto-js')
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(payload.cartao),
        CryptoJS.enc.Utf8.parse(aesKey),
        {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }).toString()
      commit('setLoading', true)
      const data = {
        cartao_criptografado: ciphertext
      }
      if (payload.cnpj_empresa) data.cnpj_empresa = payload.cnpj_empresa
      return new Promise((resolve, reject) => {
        pagamentoDao.hashCartao(data, state.headers).then(response => {
          commit('setLoading', false)
          if (response.status === 201) {
            return response.json()
          } else {
            throw Error('Não foi possível Cadastrar o cartão')
          }
        }).then(json => {
          if (localStorage.getItem(`user-${state.user.id}.cartao`) === null) {
            commit('setCartao', json)
            commit('addCartao', payload.cartao)
          }
          commit('setSuccess', 'Cartão cadastrado com sucesso!')
          resolve(json)
        }).catch(() => {
          commit('setLoading', false)
          commit('setError', 'Não foi possível cadastrar cartão.')
          reject(new Error('Não foi possível Cadastrar o cartão.'))
        })
      })
    },
    obterHash ({ commit, state }, payload) {
      commit('setLoading', true)
      var CryptoJS = require('crypto-js')
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(payload),
        CryptoJS.enc.Utf8.parse(aesKey),
        {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }).toString()

      const dados = {
        id_cartao: payload.id_cartao,
        cartao_criptografado: ciphertext
      }
      return new Promise((resolve, reject) => {
        pagamentoDao.obterHash(dados, state.headers).then(response => {
          commit('setLoading', false)
          if (response.status === 200) {
            return response.json()
          } else {
            throw Error('Não foi possível Cadastrar o cartão')
          }
        }).then(json => {
          commit('setCartao', { hash_code: json.hash_code, id_cartao: payload.id_cartao })
          commit('addCartao', payload)
          commit('setSuccess', 'Cartão ativo sucesso!')
          resolve(json)
        }).catch(() => {
          commit('setLoading', false)
          commit('setError', 'Não foi possível ativar cartão.')
          reject(new Error('Não foi possível ativar o cartão.'))
        })
      })
    },
    desativarCartao ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        commit('setDesativarCartao', null)
        commit('setSuccess', 'Cartão removido sucesso!')
        commit('setLoading', false)
        resolve('ok')
      })
    },
    listarCartoes ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentoDao.getCartoes(payload, state.headers).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            throw Error('Não foi possível Cadastrar o cartão')
          }
        }).then(json => {
          // commit('setCartoes', json)
          resolve(json)
        }).catch(() => {
          // reject(new Error('Não foi possível Cadastrar o cartão'))
        })
      })
    },
    deleteCartao ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentoDao.deleteCartao(payload, state.headers).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            localStorage.removeItem(`user-${state.user.id}.cartao`)
            commit('setLoading', true)
            resolve('OK')
          } else {
            throw Error('Não foi possível deletar o cartão')
          }
        }).catch(() => {
          reject(new Error('Não foi possível deletar o cartão'))
        })
      })
    },
    getCartao ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        if (localStorage.getItem(`user-${state.user.id}.cartao`)) {
          const cartao = localStorage.getItem(`user-${state.user.id}.cartao`).split('-')
          const id = cartao[0]
          const hash = cartao[1]
          pagamentoDao.getCartao(id, state.headers).then(response => {
            if (response.ok) {
              commit('setHash', hash)
              return response.json()
            } else {
              throw Error('Não foi possível retornar o cartão')
            }
          }).then(json => {
            resolve(json)
          }).catch(() => {
            reject(new Error('Não foi possível retornar o cartão'))
          })
        }
      })
    },
    confirmadoPagamentoCartao ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        propostaDao.submeterProposta(payload, state.headers).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            throw Error('Não foi possível submeter a proposta')
          }
        }).then(json => {
          resolve(json)
        }).catch(() => {
          reject(new Error('Não foi possível submeter a proposta'))
        })
      })
    },
    desativarProposta ({
      commit,
      state
    }, payload) {
      return new Promise((resolve, reject) => {
        propostaDao.desativarProposta(payload, state.headers).then(response => {
          if (response.ok) {
            commit('setSuccess', 'Proposta excluida com sucesso!')
          } else {
            throw Error('Não foi possível excuir a proposta')
          }
        })
      })
    },
    pagarCartao ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentoDao.pagarCartao(payload, state.headers).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            commit('setError', 'Não foi possível realizar o pagamento.')
            reject(new Error('Não foi possível adicionar como cliente. '))
            throw Error('Não foi possível realizar o pagamento')
          }
        }).then(json => {
          resolve(json)
        }).catch(() => {
          reject(new Error('Não foi possível realizar o pagamento'))
        })
      })
    },
    verificarAtualizacao ({ state, commit }, payload) {
      var version = localStorage.getItem(`${payload.name}-versao`)
      if (version !== payload.version) {
        commit('setHasNewVersion', true)
      }
    },
    atualizarVersao ({ commit }, payload) {
      localStorage.setItem(`${payload.name}-versao`, payload.version)
      commit('setVersao', payload.version)
    },
    relatorioPropostas ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        empresasDao.relatorioPropostas(payload.filter, state.empresa.cnpj, state.headers, payload.pagination).then(response => {
          if (!response.ok) {
            commit('setError', 'Não foi possível acessa relatório')
            reject(new Error('Não foi possível pegar o relatório'))
          }
          resolve(response.json())
        })
      })
    },
    postosComProposta ({ state, commit }) {
      return new Promise((resolve, reject) => {
        postoDao.comProposta(state.headers).then(response => {
          if (!response.ok) {
            commit('setError', 'Não foi possível acessar postos')
            reject(new Error('Não foi possível acessar postos'))
          }
          resolve(response.json())
        })
      })
    },
    redesComProposta ({ state, commit }) {
      return new Promise((resolve, reject) => {
        redeDao.comProposta(state.headers).then(response => {
          if (!response.ok) {
            commit('setError', 'Não foi possível acessar redes')
            reject(new Error('Não foi possível acessar redes'))
          }
          resolve(response.json())
        })
      })
    },
    pegarInformacoesVeiculo ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        veiculoDao.pegarInformacoesVeiculo(state.headers, payload).then(response => {
          if (!response.ok) {
            reject(new Error('Não foi possível acessar informações'))
          }
          resolve(response.json())
        })
      })
    },
    pinAutenticar ({ commit, state }, pin) {
      return new Promise((resolve, reject) => {
        empresasDao.autenticarPin(state.empresa.cnpj, pin, state.headers).then((response) => {
          if (!response.ok) {
            commit('setError', 'Chave inválida!')
            reject(new Error('Não foi possível autenticar'))
          } else {
            this.dispatch('addTimer')
            commit('setSuccess', 'Autenticado com sucesso')
            resolve('ok')
          }
        })
      })
    },
    detalhesFatura ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        faturamentoDao.info(payload, state.empresa.cnpj, state.headers).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            reject(response)
          }
        }).then(json => {
          resolve(json)
        })
      })
    },
    cadastrarPin ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        payload.cnpj = state.empresa.cnpj
        empresasDao.cadastrarPin(payload, state.headers).then((response) => {
          if (!response.ok) {
            commit('setError', 'Não foi possível cadastrar PIN')
            reject(new Error('Não foi possível cadastrar PIN'))
          } else {
            commit('setSuccess', 'Código cadastrado com sucesso')
            resolve('ok')
          }
        })
      })
    },
    ativarPin ({ commit, state }) {
      return new Promise((resolve, reject) => {
        empresasDao.ativarPin(state.empresa.cnpj, state.headers).then((response) => {
          if (!response.ok) {
            commit('setError', 'Não foi possível realizar operação.')
            reject(new Error('Não foi possível realizar operação.'))
          } else {
            this.dispatch('carregarPerfil')
            resolve('ok')
          }
        })
      })
    },
    sendSmsVerification ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      if (!state.appVerifier) {
        commit('setAppVerifier', new firebase.auth.RecaptchaVerifier(payload, {
          size: 'invisible'
        }))
      }
      var provider = new firebase.auth.PhoneAuthProvider()
      const phoneNumber = state.pessoa.telefone.startsWith('+55') ? state.pessoa.telefone : `+55${state.pessoa.telefone}`
      return new Promise((resolve, reject) => {
        provider.verifyPhoneNumber(phoneNumber, state.appVerifier).then(verificationId => {
          commit('setLoading', false)
          commit('setVerificationId', verificationId)
          resolve(verificationId)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    confirmSmsVerification ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      var phoneCredential = firebase.auth.PhoneAuthProvider.credential(state.verificationId, payload)
      return new Promise((resolve, reject) => {
        if (firebase.auth().currentUser.phoneNumber) {
          firebase.auth().currentUser.reauthenticateAndRetrieveDataWithCredential(phoneCredential).then((user) => {
            commit('setLoading', false)
            resolve(user)
          }).catch(error => {
            commit('setLoading', false)
            reject(error)
          })
        } else {
          firebase.auth().currentUser.updatePhoneNumber(phoneCredential).then(() => {
            commit('setLoading', false)
            resolve(true)
          }).catch(error => {
            commit('setLoading', false)
            reject(error)
          })
        }
      })
    },
    pegarCarteiraVirtual ({ state, commit }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.pegarCarteiraVirtual(state.empresa.cnpj, state.headers).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(new Error('Esta empresa não tem uma Carteira Apetrus.'))
          } else {
            resolve(response.json())
          }
        })
      })
    },
    criarCarteiraVirtual ({ state, commit }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.criarCarteiraVirtual(state.empresa.cnpj, state.headers).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(new Error('Por algum motivo, a requisição para criar a Carteira Apetrus não retornou sucesso. Entre em contato com a equipe responsável pelo back-end do projeto caso precise de mais detalhes.'))
          } else {
            resolve(response.json())
          }
        })
      })
    },
    pegarHistoricoDaCarteiraVirtual ({ state, commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.pegarHistoricoDaCarteiraVirtual(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(new Error('Por algum motivo, a requisição para pegar o histórico de transações dessa Carteira Apetrus não retornou sucesso. Entre em contato com a equipe responsável pelo back-end do projeto caso precise de mais detalhes.'))
          } else {
            resolve(response.json())
          }
        })
      })
    },
    veiculoExiste ({ state, commit }, placa) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.existe(state.headers, placa).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(new Error('Não foi possível realizar operação'))
          } else {
            resolve(response.json())
          }
        })
      })
    },
    preCadastro ({ state, commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        payload.cnpj = state.empresa.cnpj
        veiculoDao.preCadastro(payload, state.headers).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Não foi possível realizar operação')
            reject(new Error('Não foi possível realizar operação'))
          } else {
            commit('setSuccess', 'Pré-Cadastro realizado com sucesso.')
            resolve('ok')
          }
        })
      })
    },
    pegarNotificacoes ({ state, commit }) {
      return new Promise((resolve, reject) => {
        notificacoesDao.get(state.empresa.cnpj, state.headers).then(response => {
          if (!response.ok) {
            commit('setError', 'Não foi possível pegar notificacões')
            reject(new Error('Não foi possível pegar notificacões'))
          } else {
            resolve(response.json())
          }
        })
      })
    },
    responderNotificacao ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        notificacoesDao.put(payload, state.headers).then(response => {
          if (!response.ok) {
            commit('setError', 'Não foi possível responder notificação')
            reject(new Error('Não foi possível responder notificação'))
          } else {
            if (payload.opcao === 1) {
              if (payload.id_veiculo) this.dispatch('setSuccess', 'O veículo agora faz parte de sua empresa.')
              else this.dispatch('setSuccess', 'O funcionário agora faz parte de sua empresa.')
            } else if (payload.opcao === 2) {
              if (payload.id_veiculo) this.dispatch('setSuccess', 'O veículo foi rejeitado.')
              else this.dispatch('setSuccess', 'O funcionário foi rejeitado.')
            } else if (payload.opcao === 3) {
              if (payload.id_veiculo) this.dispatch('setSuccess', 'Todos os veículos foram aceitos com sucesso.')
              else this.dispatch('setSuccess', 'Todos os funcionários foram aceitos com sucesso.')
            } else if (payload.opcao === 4) {
              if (payload.id_veiculo) this.dispatch('setSuccess', 'Todos os veículos foram rejeitados.')
              else this.dispatch('setSuccess', 'Todos os funcionários foram rejeitados.')
            }
            resolve('OK')
          }
        })
      })
    },
    transferirSaldo ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.transferirSaldo(payload, state.headers).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            resolve('OK')
          } else {
            reject(new Error('Ocorreu um erro ao transferir saldo'))
          }
        })
      })
    },
    pegarMeiosDeRecebimentoApetrus ({ commit, state }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        administradorDao.pegarMeiosDeRecebimento(state.headers).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(new Error('Não foi possível pegar meios de recebimento da Apetrus.'))
          } else {
            resolve(response.json())
          }
        })
      })
    },
    adicaoDeCredito ({ commit, state }, payload) {
      /*
        Usada para GET e POST
      */
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        carteiraDigitalDao.adicaoDeCredito(state.headers, payload.method, state.empresa.cnpj, payload).then(response => {
          commit('setLoading', false)
          if (!response.ok) reject(new Error('Não foi possível realizar operação'))
          if (payload.method === 'GET') resolve(response.json())
          resolve('OK')
        })
      })
    },
    comprarCashBack ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        carteiraDigitalDao.comprarCashBack(
          state.headers,
          payload
        ).then(response => {
          commit('setLoading', false)
          if (!response.ok) reject(new Error('Não foi possível realizar operação'))
          if (payload.method === 'GET') resolve(response.json())
          resolve(response.json())
        }).catch(() => {
          reject(new Error('Não foi possível realizar compra'))
        })
      })
    },
    pegarEmpresasAtivas ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.empresasAtivas(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    verificacaoVeiculo ({ commit, state }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.verificacaoVeiculo(state.headers, state.empresa.cnpj).then(response => {
          commit('setLoading', false)
          this.dispatch('escolherEmpresa', state.empresa)
          resolve('OK')
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    verificacaoFuncionario ({ commit, state }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.verificacaoFuncionario(state.headers, state.empresa.cnpj).then(response => {
          commit('setLoading', false)
          this.dispatch('escolherEmpresa', state.empresa)
          resolve('OK')
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    cadastrarPropostaApetrus ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        propostaDao.cadastrarPropostaApetrus(payload, state.headers).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            commit('setSuccess', 'Proposta enviada com sucesso')
            resolve('OK')
          } else {
            commit('setError', 'Não foi possível enviar proposta.')
            reject(new Error('Não foi possível enviar proposta'))
          }
        })
      })
    },
    solicitarRetiradaDeSaldo ({ state, commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.solicitarRetiradaDeSaldo(state.headers, state.empresa.cnpj, payload).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            resolve('200')
          } else {
            reject(new Error('Transação não sucedida'))
          }
        })
      })
    },
    cadastrarChavePix ({ state, commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.cadastrarChavePix(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            resolve('200')
          } else {
            reject(new Error('Transação não sucedida'))
          }
        })
      })
    },
    editarEmpresa ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.editarEmpresa(payload.cnpj, payload, state.headers).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Não foi possível editar empresa')
            reject(new Error('Não foi possível editar empresa'))
          }
          commit('setSuccess', 'Operação realizada com sucesso')
          resolve('ok')
        })
      })
    },
    cadastrarFilial ({ state, commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.cadastrarFilial(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Não foi possível cadastrar empresa filial.')
            reject(new Error('Não foi possível cadastrar empresa filial.'))
          }
          commit('setSuccess', 'Empresa filial cadastrada com sucesso.')
          resolve('ok')
        })
      })
    },
    listarFiliais ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.listarfiliais(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    listarVeiculosAVenda ({ commit, state }, payload) {
      // Mostra Veículos avenda ou veículos da empresa para vender ou trocar
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.aVenda(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    listarTipoVeiculo ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        tipoVeiculoDao.get(state.headers).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    detalharVeiculo ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.detalhar(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    redesSociais ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.redeSocial(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            if (payload.method === 'POST') commit('setSuccess', 'Rede social cadastrada com sucesso')
            else if (payload.method === 'DELETE') commit('setSuccess', 'Rede social excluida com sucesso')
            else resolve(response.json())
            resolve('ok')
          } else {
            if (payload.method === 'POST') commit('setError', `Você já cadastrou um ${payload.nome} para sua empresa.`)
            else if (payload.method === 'DELETE') commit('setError', 'Não foi possível excluir rede social')
            else commit('setError', 'Não foi possível pegar redes sociais')
          }
        }).catch(error => {
          commit('setLoading', false)
          commit('setError', 'Erro ao realizar operação')
          reject(error)
        })
      })
    },
    solicitacaoVeiculo ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.solicitacao(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            if (payload.method === 'POST') commit('setSuccess', 'Solicitacão enviada com sucesso.')
            else if (payload.method === 'GET') resolve('ok')
            else if (payload.method === 'DELETE') {
              commit('setSuccess', 'Solicitação excluída com sucesso.')
              resolve('ok')
            } else if (payload.method === 'PATCH') commit('setSuccess', 'O seu pagamento foi realizado com sucesso.')
            resolve(response.json())
          } else {
            if (payload.method === 'POST') commit('setError', 'Não foi possivel enviar proposta')
            else if (payload.method === 'DELETE') commit('setError', 'Não foi possível excluir proposta')
            else if (payload.method === 'GET') commit('setError', 'Você já enviou uma proposta para esse cliente.')
            else if (payload.method === 'PATCH') commit('setError', 'Não foi possível realizar o seu pagamento.')
            reject(new Error('Erro ao realizar operação'))
          }
        }).catch(error => {
          commit('setLoading', false)
          commit('setError', 'Erro ao realizar operação')
          reject(error)
        })
      })
    },
    pegarPropostasVeiculos ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.pegarPropostasVeiculos(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    horarioFuncionamento ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.horarioFuncionamento(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            if (payload.method === 'POST') resolve('ok')
            else resolve(response.json())
          } else {
            if (payload.method === 'POST') commit('setError', 'Não foi possível cadastrar horário de funcionamento.')
            reject(new Error('Erro ao realizar operação'))
          }
        }).catch(error => {
          commit('setLoading', false)
          commit('setError', 'Erro ao realizar operação')
          reject(error)
        })
      })
    },
    detalharEmpresa ({ commit, state }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        empresasDao.detalhar(state.headers, state.empresa.cnpj).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    editarPropostaVeiculo ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.editarPropostaVeiculo(state.headers, payload.data, payload.id, state.empresa.cnpj).then(response => {
          commit('setLoading', false)
          resolve('Ok')
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    colocarAvenda ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.colocarAvenda(state.headers, payload, state.empresa.cnpj).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Não foi possível anunciar veículo. Verifique se o véiculo foi negociado com algum cliente.')
            reject(new Error('Não foi possível colocar veículo a venda'))
          }
          resolve('Ok')
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    pegarDetalhesVeiculo ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.pegarDetalhes(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    opcoesFinanciamento ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.opcoesFinanciamento(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            if (payload.method === 'POST') commit('setSuccess', 'Opção de financiamento cadastrada com sucesso.')
            else if (payload.method === 'DELETE') commit('setSuccess', 'Opção de financiamento deletada com sucesso.')
            else resolve(response.json())
            resolve('ok')
          } else {
            if (payload.method === 'POST') commit('setError', 'Opção de financiamento já existe.')
            if (payload.method === 'DELETE') commit('setError', 'Não foi possível excluir opção de financiamento.')
            reject(new Error('Erro ao realizar operação'))
          }
        }).catch(error => {
          commit('setLoading', false)
          commit('setError', 'Erro ao realizar operação')
          reject(error)
        })
      })
    },
    pegarComprasAutomacao ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        comprasDao.pegarComprasPostos(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    numeroPostos ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        postoDao.numeroIntegrados(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    pegarSolicitacoes ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        solicitacaoDao.getList(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    solicitacao ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        solicitacaoDao.multipleMethods(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            if (payload.method === 'POST') {
              commit('setSuccess', 'Sua solicitação foi enviada com sucesso. A Apetrus responderá assim que possível.')
              resolve('ok')
            } else if (payload.method === 'DELETE') {
              commit('setSuccess', 'Essa solicitação foi excluída com sucesso.')
              resolve('ok')
            } else resolve(response.json())
          } else {
            if (payload.method === 'POST') commit('setError', 'Não foi possível enviar solicitação.')
            else if (payload.method === 'DELETE') commit('setError', 'Não foi possível excluir opção essa solicitação.')
            reject(new Error('Erro ao realizar operação'))
          }
        })
      })
    },
    pegarFilaDeEspera ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.filaDeEspera(state.headers, payload).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    getMeiosRecebimento ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        meioRecebimentoDao.get(state.headers, payload)
          .then(response => {
            commit('setLoading', false)
            resolve(response.json())
          })
          .catch(error => {
            commit('setLoading', false)
            reject(error)
          })
      })
    },
    getConfigApetrus ({ commit, state }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        configuracoesApetrusDao.get(state.headers)
          .then(response => {
            commit('setLoading', false)
            resolve(response.json())
          })
          .catch(error => {
            commit('setLoading', false)
            reject(error)
          })
      })
    },
    transacoesPagPixBank ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        if (!payload || !payload.method) reject(new Error('Method não enviado'))
        pagPixBankDao.transacoes(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            if (payload.method === 'POST') commit('setError', 'Não foi possível gerar QRcode.')
            if (payload.method === 'DELETE') commit('setError', 'Não foi possível cancelar transação')
            reject(new Error('Ocorreu um erro.'))
          } else {
            return response.json()
          }
        }).then(json => {
          if (payload.method === 'POST') {
            const imagemDecode = `data:image/png;base64,${json.pagamentoQrCode}`
            commit('setQrcodeCobranca', imagemDecode)
          }
          if (payload.method === 'DELETE') commit('setSuccess', 'Transação cancelada')
          commit('setLoading', false)
          resolve(json)
        })
      })
    },
    pagamento ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        const funcao = payload.method === 'GET' ? paymentsDao.verifyPayment : paymentsDao.pay
        funcao(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (!response.ok) reject(new Error('Ocorreu um erro.'))

          if (payload.pagamento_secundario) {
            response.json().then(json => {
              if (payload.method === 'POST') {
                if (payload.nomeMeioRecebimento === 'pagpixbank') {
                  commit('setQrcodeCobranca', `https://chart.googleapis.com/chart?cht=qr&chs=500x500&chld=H&chl=${json.pagamento}`)
                } else if (payload.nomeMeioRecebimento === 'gerencianetpix') {
                  commit('setQrcodeCobranca', json.imagemQrcode)
                }
              }
              resolve(json)
            })
          } else resolve('OK')
        }).catch(() => {
          commit('setLoading', false)
          reject(new Error('Ocorreu um erro.'))
        })
      })
    },
    pegarCoordenadasGoogle ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        coordenadas.getCoordenadasGoogle(
          payload.logradouro,
          payload.numero,
          payload.bairro,
          payload.cidade,
          payload.estado
        ).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(new Error('Não foi possível pegar coordenadas'))
          }
          return response.json()
        }).then(json => {
          commit('setLoading', false)
          if (json.results.length === 0) {
            reject(new Error('Não foi possível pegar coordenadas'))
          }
          if (payload.retornar_tudo) {
            resolve(json.results)
          }
          resolve({
            latitude: json.results[0].geometry.location.lat,
            longitude: json.results[0].geometry.location.lng
          })
        }).catch((e) => {
          console.log(e)
          commit('setLoading', false)
          reject(new Error('Não foi possível pegar coordenadas'))
        })
      })
    },
    async pegarCoordenadas ({ commit }, payload) {
      commit('setLoading', true)
      const response = await coordenadas.getCoordenadas(
        payload.logradouro,
        payload.numero,
        payload.bairro,
        payload.cidade,
        payload.estado
      )
      commit('setLoading', false)
      if (!response.ok) {
        const retorno = await this.dispatch('pegarCoordenadasGoogle', payload)
        return retorno
      }

      const json = await response.json()
      commit('setLoading', false)
      if (json.length === 0) {
        const retorno = await this.dispatch('pegarCoordenadasGoogle', payload)
        return retorno
      }
      if (payload.retornar_tudo) {
        return json
      }
      return {
        latitude: json[0].lat,
        longitude: json[0].lon
      }
    },
    listPagamentosExtra ({ commit, state }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentosExtraDao
          .listar(state.headers, state.empresa.cnpj)
          .then((response) => {
            if (!response.ok) {
              reject(Error('Não foi possível pegar meios de recebimento'))
            }
            commit('setLoading', false)
            return response.json()
          })
          .then((json) => {
            commit('setPagamentosExtra', json)
            resolve(json)
          })
      })
    },
    desvincularPagamentoExtra ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentosExtraDao.desvincularPagamento(
          state.headers,
          payload
        ).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            this.dispatch('listPagamentosExtra')
            this.dispatch('carregarPagamentosSecundarios')
            resolve('Forma de pagamento apagada com sucesso.')
          } else {
            throw new Error('Desculpe, mas a forma de pagamento não pôde ser apagada.')
          }
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    vincularPagamentoExtra ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentosExtraDao.vincularPagamento(
          state.headers,
          payload
        ).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            if (!payload.naoCarregar) {
              this.dispatch('listPagamentosExtra')
              this.dispatch('carregarPagamentosSecundarios')
            }
            resolve('Forma de pagamento vinculada com sucesso.')
          } else {
            throw new Error('Desculpe, mas a forma de pagamento não pôde ser vinculada.')
          }
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    carregarPagamentosSecundarios ({
      commit,
      state
    }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentosExtraDao.listarSecundarios(state.headers, state.empresa.cnpj).then(response => {
          commit('setLoading', false)
          return response.json()
        }).then(json => {
          commit('setPagamentosSecundarios', json)
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    habilitarPagamentoExtra ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentosExtraDao.habilitarPagamento(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            this.dispatch('listPagamentosExtra')
            this.dispatch('carregarPagamentosSecundarios')
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    },
    ativarPagamentoExtra ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentosExtraDao.ativarPagamento(
          state.headers,
          payload
        ).then(response => {
          commit('setLoading', false)
          if (response.ok) {
            this.dispatch('listPagamentosExtra')
            this.dispatch('carregarPagamentosSecundarios')
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    },
    carregarFormasPagamento ({ commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        pagamentoDao.listFormasPagamento()
          .then((response) => {
            commit('setLoading', false)
            return response.json()
          }).then((json) => {
            commit('setLoading', false)
            if (payload && payload.page) {
              commit('setFormasPag', json.results)
              commit('setTotalItemsFormasPag', json.count)
            } else {
              resolve(json)
            }
          }).catch((error) => {
            console.log(error)
            commit('setLoading', false)
            reject(
              new Error(
                'Não foi possível pegar formas de pagamento'
              )
            )
          })
      })
    },
    apagarDadosCartao ({ commit, state }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        commit('setCartao', undefined)
        commit('setLoading', false)
        resolve('OK')
      })
    },
    pegarItens ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        itemDao.get(state.headers, payload).then(response => {
          return response.json()
        }).then(json => {
          commit('setLoading', false)
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    itens ({ commit, state }, payload) {
      /*
        Usada para GET, DELETE, PUT e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        itemDao
          .item(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              if (payload.method === 'GET') reject(new Error('Não foi possível pegar itens'))
              else if (payload.method === 'POST') {
                commit('setErrot', 'Não foi possível cadastrar item')
                reject(new Error('Não foi possível cadastrar item'))
              } else if (payload.method === 'PUT') {
                commit('setErrot', 'Não foi possível atualizar item')
                reject(new Error('Não foi possível atualizar item'))
              } else if (payload.method === 'DELETE') {
                commit('setErrot', 'Não foi possível desativar item')
                reject(new Error('Não foi possível desativar item'))
              }
            }
            if (payload.method === 'DELETE') resolve('ok')
            else {
              resolve(response.json())
            }
          })
      })
    },
    pegarCompras ({
      commit,
      state
    }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        comprasDao.pegarComprasEmpresa(state.headers, payload).then(response => {
          return response.json()
        }).then(json => {
          commit('setLoading', false)
          resolve(json)
        }).catch(error => {
          commit('setLoading', false)
          reject(error)
        })
      })
    },
    pegarCategorias ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        categoriaDao
          .get(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              reject(new Error('Não foi possível pegar itens'))
            }
            resolve(response.json())
          })
      })
    },
    codigoPromocional ({ commit, state }, payload) {
      /*
        Usada para GET, DELETE, PUT e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        codigosPromocionaisDao
          .viewSet(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              if (payload.method === 'GET') reject(new Error('Não foi possível pegar códigos'))
              else if (payload.method === 'POST') {
                commit('setErrot', 'Não foi possível cadastrar código promocional')
                reject(new Error('Não foi possível cadastrar código promocional'))
              } else if (payload.method === 'PUT') {
                commit('setErrot', 'Não foi possível atualizar código promocional')
                reject(new Error('Não foi possível atualizar código promocional'))
              } else if (payload.method === 'DELETE') {
                commit('setErrot', 'Não foi possível desativar código promocional')
                reject(new Error('Não foi possível desativar código promocional'))
              }
            }
            if (payload.method === 'DELETE') resolve('ok')
            else {
              resolve(response.json())
            }
          })
      })
    },
    oferta ({ commit, state }, payload) {
      /*
        Usada para GET, DELETE, PUT e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        ofertasDao
          .viewSet(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              if (payload.method === 'GET') reject(new Error('Não foi possível pegar ofertas'))
              else if (payload.method === 'POST') {
                commit('setErrot', 'Não foi possível cadastrar oferta')
                reject(new Error('Não foi possível cadastrar oferta'))
              } else if (payload.method === 'PUT') {
                commit('setErrot', 'Não foi possível atualizar oferta')
                reject(new Error('Não foi possível atualizar oferta'))
              } else if (payload.method === 'DELETE') {
                commit('setErrot', 'Não foi possível desativar oferta')
                reject(new Error('Não foi possível desativar oferta'))
              }
            }
            if (payload.method === 'DELETE') resolve('ok')
            else {
              resolve(response.json())
            }
          })
      })
    },
    pegarClientes ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        clientesDao
          .meusClientes(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              reject(new Error('Não foi possível pegar clientes'))
            }
            resolve(response.json())
          })
      })
    },
    pushNotification ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        notificacoesDao.push(state.headers, payload).then(response => {
          if (response.ok) {
            resolve('ok')
          }
          reject(new Error('Não foi possível enviar motificação'))
        }).catch(() => {
          reject(new Error('Não foi possível enviar motificação'))
        })
      })
    },
    enviarEmail ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        payload.empresa = state.empresa.cnpj
        pessoasDao.enviarEmailParaClientes(payload, state.headers).then(response => {
          if (response.ok) {
            commit('setSuccess', 'E-mail enviado com sucesso!')
            resolve('ok')
          }
          commit('setError', 'Não foi possível enviar e-mail! Verifique as suas informações com o seu serviço de e-mail')
          reject(new Error('Não foi possível enviar e-mail'))
        }).catch(() => {
          commit('setError', 'Não foi possível enviar e-mail! Verifique as suas informações com o seu serviço de e-mail')
          reject(new Error('Não foi possível enviar e-mail'))
        })
      })
    },
    premios ({ commit, state }, payload) {
      /*
        Usada para GET, DELETE, PUT e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        premioDao
          .allMethods(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              if (payload.method === 'GET') reject(new Error('Não foi possível pegar prêmios'))
              else if (payload.method === 'POST') {
                commit('setError', 'Não foi possível cadastrar prêmio')
                reject(new Error('Não foi possível cadastrar prêmio'))
              } else if (payload.method === 'PUT') {
                commit('setError', 'Não foi possível atualizar prêmio')
                reject(new Error('Não foi possível atualizar prêmio'))
              } else if (payload.method === 'DELETE') {
                commit('setError', 'Não foi possível deletar prêmio')
                reject(new Error('Não foi possível deletar prêmio'))
              }
            }
            if (payload.method === 'DELETE') {
              commit('setSuccess', 'Prêmio deletado com sucesso!')
              resolve('ok')
            } else {
              if (payload.method === 'POST') commit('setSuccess', 'Prêmio cadastrado com sucesso!')
              return response.json()
            }
          }).then(json => {
            resolve(json)
          })
      })
    },
    cadastrarAssinatura ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        assinaturaDao.post(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            response.json().then(json => {
              resolve(json.mensagem)
            })
            commit('setSuccess', 'As informações foram salvas com sucesso!')
          } else {
            response.json().then(json => {
              reject(new Error(json.mensagem))
            })
          }
        })
      })
    },
    deletarAssinatura ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        assinaturaDao.deletar(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            commit('setSuccess', 'Plano de Assinatura deletado com sucesso!')
            resolve('OK')
          } else {
            reject(new Error('Ocorreu um erro ao tentar realizar operação.'))
          }
        })
      })
    },
    pegarPlanos ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        assinaturaDao.get(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            resolve(response.json())
          } else {
            reject(new Error('Ocorreu um erro ao tentar realizar operação.'))
          }
        })
      })
    },
    frentistas ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        payload.empresa = state.empresa.cnpj
        frentistaDao.get(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            reject(new Error('Não foi possível pegar frentistas'))
          }
        }).then(json => {
          if (payload.tudo) {
            if (json.results) {
              commit('setFrentistas', json.results)
            } else {
              commit('setFrentistas', json)
            }
          }
          resolve(json)
        })
      })
    },
    inserirFrentista ({
      commit,
      state
    }, frentista) {
      var body = frentista.id_pessoa ? {
        id_pessoa: frentista.id_pessoa,
        matricula: frentista.matricula
      } : frentista
      body.empresa = state.empresa.cnpj
      return new Promise((resolve, reject) => {
        if (!state.empresa || (state.empresa && !state.empresa.ativo)) {
          commit('setError', 'Esse posto está desativado no sistema')
          reject(new Error('Esse posto está desativado no sistema'))
          return
        }
        frentistaDao.inserirFrentista(state.headers, body).then(response => {
          if (response.ok) {
            response.json().then(json => {
              commit('setSuccess', 'Frentista inserido com sucesso!')
              commit('addFrentista', json)
              resolve(json)
            })
          } else {
            response.text().then(e => {
              commit('setError', 'Não foi possível inserir frentista.')
              reject(e)
            })
          }
        })
      })
    },
    removerFrentista ({
      commit,
      state
    }, payload) {
      if (!state.empresa || (state.empresa && !state.empresa.ativo)) {
        alert('Esse posto está desativado no sistema')
        return
      }
      commit('setLoading', true)
      payload.empresa = state.empresa.cnpj
      frentistaDao.inativarFrentista(state.headers, payload).then((response) => {
        commit('setLoading', false)
        if (!response.ok) {
          if (response.status === 422) {
            commit('setError', 'Não possível remover o frentista.')
          }
        } else {
          commit('setSuccess', 'Frentista removido com sucesso!')
          commit('removeFrentista', payload)
        }
      })
    },
    editarFrentista ({ commit, state }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        payload.empresa = state.empresa.cnpj
        frentistaDao.put(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            return response.json()
          } else {
            commit('setError', 'Não foi possível alterar as informações do frentistas')
            reject(new Error('Não foi possível editar frentistas'))
          }
        }).then(json => {
          commit('setSuccess', 'As informações foram salvas com sucesso!')
          commit('changeFrentistas', json)
          resolve(json)
        })
      })
    },
    cadastrarUsuarioFrentista ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (!state.empresa || (state.empresa && !state.empresa.ativo)) {
          commit('setError', 'Esse posto está desativado no sistema!')
          reject(new Error('Esse posto está desativado no sistema'))
          return
        }
        commit('setLoading', true)
        payload.empresa = state.empresa.cnpj
        frentistaDao.cadastrarUsuarioFrentista(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Não foi possível cadastrar o usuário!')
            reject(new Error('Não foi possível cadastrar o usuário!'))
          } else {
            commit('setSuccess', 'Usuário cadastrado com sucesso!')
            resolve(response.json())
          }
        })
      })
    },
    consultarFrentistaCPF ({ commit, state }, cpf) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        pessoasDao.verificarPessoa(cpf, state.headers).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(new Error('Usuário não existe'))
          } else {
            resolve(response.json())
          }
        })
      })
    },
    pegarBandeirasCartao ({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        bandeiraCartaoDao.get(state.headers).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(Error('Não foi possível compra'))
          }
          resolve(response.json())
        })
      })
    },
    carregarTiposVeiculo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        veiculoDao.getTiposVeiculo(state.headers).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(Error('Não foi possível carregar tipos de veículos'))
          }
          resolve(response.json())
        })
      })
    },
    enviarRequisicaoGenerica ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.method) {
          commit('setError', 'Metodo não enviado')
          reject(Error('Metodo não enviado'))
        }
        if (!payload.headers) {
          commit('setError', 'Headers não enviado')
          reject(Error('Metodo não enviado'))
        }
        if (payload.method !== 'GET' && !payload.body) {
          commit('setError', 'Corpo não enviado')
          reject(Error('Corpo não enviado'))
        }
        if (!payload.url) {
          commit('setError', 'URL não enviado')
          reject(Error('URL não enviado'))
        }
        let url = payload.url
        const data = {
          method: payload.method,
          mode: 'cors',
          headers: payload.headers
        }
        if (payload.method === 'GET') data.cache = 'default'
        else data.body = JSON.stringify(payload.body)

        if (payload.parametros) {
          const keys = Object.keys(payload.parametros)
          for (let i = 0; i < keys.length; i++) {
            url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${payload.parametros[keys[i]]}`
          }
        }
        commit('setLoading', true)
        fetch(url, data).then(response => {
          commit('setLoading', false)
          resolve(response.json())
        })
      })
    },
    provedorSMTP ({ commit, state }, payload) {
      /*
        Usada para GET, DELETE, PUT e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        provedorSMTPDao.gerenciar(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            if (payload.method === 'POST') commit('setError', 'Erro no cadastro provedor de e-mail')
            else if (payload.method === 'DELETE') commit('setError', 'Erro ao tentar deletar provedor de e-mail')
            else if (payload.method === 'PUT') commit('setError', 'Erro no atualizar do provedor de e-mail')
            reject(new Error('Ocorreu um erro'))
          }
          if (payload.method === 'POST' || payload.method === 'DELETE') {
            if (payload.method === 'POST') commit('setSuccess', 'E-mail atualizado com sucesso.')
            else if (payload.method === 'DELETE') commit('setSuccess', 'E-mail atualizado com sucesso.')
            this.dispatch('escolherEmpresa', state.empresa)
          } else if (payload.method === 'PUT') commit('setSuccess', 'Provedor atualizado com sucesso.')
          if (payload.method !== 'DELETE') resolve(response.json())
          resolve('OK')
        })
      })
    },
    pegarOpcionaisDoVeiculo ({ commit, state }) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        veiculoDao.opcionais(state.headers).then((response) => {
          commit('setLoading', false)
          if (response.ok) {
            resolve(response.json())
          } else {
            reject(new Error('Ocorreu um erro ao tentar realizar operação.'))
          }
        })
      })
    },
    fotos ({ commit, state }, payload) {
      /*
        Usada para GET, DELETE, PUT e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        FotoDao
          .multMethods(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              if (payload.method === 'GET') reject(new Error('Não foi possível pegar fotos'))
              else if (payload.method === 'POST') {
                commit('setErrot', 'Não foi possível cadastrar foto')
                reject(new Error('Não foi possível cadastrar foto'))
              } else if (payload.method === 'PUT') {
                commit('setErrot', 'Não foi possível atualizar foto')
                reject(new Error('Não foi possível atualizar foto'))
              } else if (payload.method === 'DELETE') {
                commit('setErrot', 'Não foi possível apagar foto')
                reject(new Error('Não foi possível apagar foto'))
              }
            }
            if (payload.method === 'DELETE') resolve('ok')
            else {
              resolve(response.json())
            }
          })
      })
    },
    cadastrarVeiculo ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        veiculoDao.insert(payload, state.headers).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            commit('setError', 'Não foi possível realizar cadastro.')
            reject(new Error('Não foi possível realizar cadastro.'))
          }
          resolve({
            status: response.status,
            retorno: response.json()
          })
        }).catch(() => {
          commit('setLoading', false)
          commit('setError', 'Não foi possível realizar cadastro.')
          reject(new Error('Não foi possível realizar cadastro.'))
        })
      })
    },
    leilaoVeiculos ({ commit, state }, payload) {
      /*
        Usada para GET, DELETE, PUT e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        leilaoDao
          .multMethods(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              if (payload.method === 'GET') reject(new Error('Não foi possível pegar disputas'))
              else if (payload.method === 'POST') {
                commit('setError', 'Não foi possível cadastrar disputa de lances')
                reject(new Error('Não foi possível cadastrar disputa de lances'))
              } else if (payload.method === 'PUT') {
                commit('setError', 'Não foi possível atualizar disputa de lances')
                reject(new Error('Não foi possível atualizar disputa de lances'))
              } else if (payload.method === 'DELETE') {
                commit('setError', 'Não foi possível apagar disputa de lances')
                reject(new Error('Não foi possível apagar disputa de lances'))
              }
            }
            if (payload.method === 'DELETE') resolve('ok')
            else {
              resolve(response.json())
            }
          })
      })
    },
    lancesLeilao ({ commit, state }, payload) {
      /*
        Usada para GET, DELETE, PUT e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        leilaoDao
          .lances(state.headers, payload)
          .then((response) => {
            commit('setLoading', false)
            if (!response.ok) {
              if (payload.method === 'GET') reject(new Error('Não foi possível pegar lances'))
              else if (payload.method === 'POST') {
                commit('setError', 'Não foi possível cadastrar lance')
                reject(new Error('Não foi possível cadastrar lance'))
              } else if (payload.method === 'PUT') {
                commit('setError', 'Não foi possível atualizar lance')
                reject(new Error('Não foi possível atualizar lance'))
              } else if (payload.method === 'DELETE') {
                commit('setError', 'Não foi possível apagar lance')
                reject(new Error('Não foi possível apagar lance'))
              }
            }
            if (payload.method === 'GET') resolve(response.json())
            else {
              if (payload.method === 'DELETE') commit('setSuccess', 'O lance foi cancelado com sucesso')
              else if (payload.method === 'POST') commit('setSuccess', 'O lance foi realizado com sucesso')
              else if (payload.method === 'PUT') commit('setSuccess', 'O lance foi alterado com sucesso')
              resolve('ok')
            }
          })
      })
    },
    realizarPagamentoLeilao ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        leilaoDao.realizarPagamento(state.headers, payload).then(response => {
          commit('setLoading', false)
          if (!response.ok) {
            reject(Error('Não foi possível realizar pagamento do disputa de lances'))
          }
          resolve('OK')
        })
      })
    },
    integracaoAutomatica ({ commit, state }, payload) {
      /*
        Usada para GET, PATCH, DELETE e POST
      */
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        integracaoAutomaticaDao.gerenciar(state.headers, payload).then((response) => {
          commit('setLoading', false)
          if (!response.ok) {
            if (payload.method === 'POST') commit('setError', 'Não foi possível realizar cadastro.')
            reject(new Error('Ocorreu um erro'))
          }
          if (payload.method === 'DELETE') {
            commit('setSuccess', 'Integração Automática apagada com sucesso.')
            resolve('OK')
          } else if (payload.method === 'POST') commit('setSuccess', 'Integração automática cadastrada com sucesso.')
          else if (payload.method === 'PATCH') commit('setSuccess', 'Integração automática atualizada com sucesso.')
          resolve(response.json())
        })
      })
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    headers (state) {
      return state.headers
    },
    pessoa (state) {
      return state.pessoa
    },
    empresa (state) {
      return state.empresa
    },
    empresas (state) {
      return state.empresas
    },
    veiculos (state) {
      return state.veiculos
    },
    veiculos_por_funcionario (state) {
      return state.veiculosPorFuncionario
    },
    creditos (state) {
      return state.creditos
    },
    recargassolicitadas (state) {
      return state.recargargasSolicitadas
    },
    loading (state) {
      return state.loading
    },
    error (state) {
      return state.error
    },
    success (state) {
      return state.success
    },
    endereco (state) {
      return state.endereco
    },
    lastPage (state) {
      return state.lastPage
    },
    combustiveis (state) {
      return state.combustiveis
    },
    ofertas (state) {
      return state.ofertas
    },
    oferta (state) {
      return state.oferta
    },
    posto (state) {
      return state.posto
    },
    promocoesPorPosto (state) {
      return state.promocoesPorPosto
    },
    ofertasPorPosto (state) {
      return state.ofertasPorPosto
    },
    propostas (state) {
      return state.propostas
    },
    veiculo (state) {
      return state.veiculo
    },
    funcionarios (state) {
      return state.funcionarios
    },
    tipos_pagamento (state) {
      return state.tipos_pagamento
    },
    funcionario (state) {
      return state.funcionario
    },
    grupoVeiculos (state) {
      return state.grupoVeiculos
    },
    vouchers (state) {
      return state.vouchers
    },
    totalItemsPage (state) {
      return state.totalItemsPage
    },
    politicasDePrivacidade (state) {
      return state.politicasDePrivacidade
    },
    termosDeUso (state) {
      return state.termosDeUso
    },
    postos (state) {
      return state.postos
    },
    hash (state) {
      return state.hash
    },
    redes (state) {
      return state.redes
    },
    datagrafic (state) {
      return state.datagrafic
    },
    compras (state) {
      return state.compras
    },
    compras_no_posto (state) {
      return state.compras_no_posto
    },
    total_relatorio_compras (state) {
      return state.total_relatorio_compras
    },
    total_relatorio_compras_posto (state) {
      return state.total_relatorio_compras_no_posto
    },
    total_relatorio_vouchers (state) {
      return state.total_relatorio_vouchers
    },
    recarga (state) {
      return state.recarga
    },
    cartoes (state) {
      return state.cartoes
    },
    cartao (state) {
      if (state.cartao === null && state.user !== null && localStorage.getItem(`user-${state.user.id}.cartao`) !== null) {
        return localStorage.getItem(`user-${state.user.id}.cartao`)
      }
      return state.cartao
    },
    isNotPessoa (state) {
      return state.isNotPessoa
    },
    getTodoById: (state) => (id) => {
      return state.cartoes.find(cartoe => cartoe.id_cartao === id)
    },
    hasNewVersion (state) {
      return state.hasNewVersion
    },
    versao (state) {
      return state.versao
    },
    timer (state) {
      return state.timer
    },
    smartLock (state) {
      return state.smartLock
    },
    estados (state) {
      return state.estados
    },
    qrcodecobranca (state) {
      return state.qrcodeCobranca
    },
    pagamentosExtra (state) {
      return state.pagamentosExtra || []
    },
    pagamentosSecundarios (state) {
      return state.pagamentosSecundarios || []
    },
    frentistas (state) {
      return state.frentistas
    }
  }
})
