<template>
  <v-img
    :src="require('@/assets/background.svg')"
    cover
    :max-width="'100%'"
    :max-height="'100vh'"
    position="top center"
  >
    <v-container
      fluid
      fill-height
      class="pa-0"
      style="overflow-y: auto; overflow-x: hidden"
    >
      <v-navigation-drawer
        absolute
        app
        v-model="drawer"
        width="40vh"
        class="grey lighten-4"
      >
        <v-layout column fill-height>
          <v-list two-line>
            <v-list-item v-if="user" class="pt-4">
              <v-layout align-center justify-center column>
                <v-chip color="accent" dark close @click:close="sair()">{{
                   formatarEmail(user.email)
                }}</v-chip>
              </v-layout>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <div class="scroll-y" style="max-height: calc(100% - 130px)">
            <v-list dense>
              <template v-for="item in menu_list">
                <v-list-item
                  :key="item.text"
                  ripple
                  @click="$router.push(item.route)"
                  :disabled="(item.tipo != 0 && empresa && !empresa.ativo) || item.route === $route.path"
                >
                  <v-list-item-action>
                    <v-icon :color="item.route === $route.path ? 'grey' : 'black'">{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      :class="`${item.route === $route.path ? 'gray' : 'black'}--text`"
                    >
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </div>
        </v-layout>
      </v-navigation-drawer>
      <v-navigation-drawer
        fixed
        absolute
        app
        v-model="notifications"
        v-if="notifications"
        right
        width="35vh"
        style="background-color: rgba(230,230,230, 0.4)"
      >
        <v-layout column fill-height>
          <h3 class="mt-4 subheading font-weight-medium" style="text-align: center;">Notificações</h3>
          <v-list>
            <template v-for="(notificacao, index) in notificacoes">
              <v-divider
                v-if="index > 0"
                :key="`${index}d`"
                inset
              />
              <v-menu
                offset-x
                :key="`${index}n`"
                left
                transition="slide-x-reverse-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item
                    v-on="on"
                    :key="`${index}l`"
                    @click="() => {}"
                  >
                    <v-list-item-avatar>
                      <v-icon size="30">circle_notifications</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title style="font-weight: 500;">{{notificacao.title}}</v-list-item-title>
                      <p style="font-size: 0.8em;">{{notificacao.subtitle}}</p>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list  v-if="notificacao.actions">
                  <template v-for="(action, index) in notificacao.actions">
                  <v-list-item ripple @click="action.function(notificacao)" :key="index">
                    <v-list-item-content>
                      <v-list-item-title
                        :class="`text-xs-center body-2 ${action.color}--text`"
                        >{{action.text}}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <v-icon :color="action.color">{{action.icon}}</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </template >
                </v-list>
                <v-list v-else>
                  <v-list-item  @click="visualizar(notificacao.object, notificacao.type)">
                    <v-list-item-avatar>
                      <v-icon color="info">visibility</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        Visualizar
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-list>
        </v-layout>
      </v-navigation-drawer>
      <v-app-bar color="transparent" app fixed class="elevation-0">
        <v-btn fab dark small color="primary" @click="drawer = !drawer">
          <v-icon size="18">menu</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-chip
          close
          text-color="white"
          color="primary"
          v-if="empresa"
          @click:close="closeEmpresa()"
          >{{ empresa.nome_fantasia }}</v-chip
        >
        <v-tooltip bottom v-if="smartLockAtivo">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab small class="ml-2" color="error" v-if="!smartLock" @click="authenticationDialog = true">
              <v-icon size="19">lock</v-icon>
            </v-btn>
            <v-btn v-on="on" rounded small dark color="success" class="ml-2 pa-4" v-else  @click="restoreTime">
              <v-spacer></v-spacer>
              {{formatedTimer}}
              <v-spacer></v-spacer>
              <v-icon size="19">lock_open</v-icon>
            </v-btn>
        </template>
        Smart Lock
      </v-tooltip>
      <v-tooltip bottom class="ml-3">
        <template v-slot:activator="{ on }">
          <v-badge
            v-on="on"
            bordered
            overlap
            :content="notificacoes.length"
            class="ml-4 mr-4"
          >
            <v-btn dark @click="notifications = !notifications" v-on="on" color="primary" fab small><v-icon>notifications</v-icon></v-btn>
          </v-badge>
        </template>
        <span>Notificações</span>
      </v-tooltip>
      </v-app-bar>
      <v-main app style="height: 100%">
        <v-alert
          v-if="hasNewVersion || updateVersion"
          :value="true"
          type="error"
          class="pa-1"
          icon="update"
        >
          <v-layout>
            <div class="mt-2">Uma nova atualização está disponível</div>
            <v-spacer></v-spacer>
            <v-btn outlined elevation="2" small @click="atualizar">Atualizar</v-btn>
          </v-layout>
        </v-alert>
        <v-alert
          text
          type="error"
          v-if="empresa && !empresa.ativo"
        >
            Sua empresa está desativada, aguarde a aprovação da Apetrus para poder utilizar todas as funções.
        </v-alert>
        <v-layout column justify-center align-center v-if="!smartLock && smartLockAtivo" class="pa-4">
          <v-flex class="text-xs-center headline primary--text text-xs-center">
            Acesso bloqueado através do SmartLock
          </v-flex>
          <v-flex class="subheading text-xs-center grey--text text--darken-2 font-weight-medium">
              Clique no cadeado ao canto superior direito da tela para desbloquear
          </v-flex>
        </v-layout>
        <div
          v-else
          id="scrolling-techniques"
          class="scroll-y ml-3 mr-3"
          style="max-height: calc(100vh - 64px)"
        >
          <router-view></router-view>
        </div>
      </v-main>
      <v-dialog :value="dialogEmpresa" scrollable persistent max-width="25em">
        <empresa-select v-on:empresaSelected="empresaSelected"></empresa-select>
      </v-dialog>
      <v-dialog :value="dialogCadEmpresa" v-if="dialogCadEmpresa" persistent max-width="500px">
        <dialog-inicio-empresa></dialog-inicio-empresa>
      </v-dialog>
    </v-container>
    <v-snackbar
      v-model="snackbar.ativo"
      :color="snackbar.color"
      :timeout="4000"
      :vertical="true"
    >
      {{ snackbar.text }}
      <v-spacer></v-spacer>
      <v-btn @click="snackbar.active = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="authenticationDialog" v-if="authenticationDialog" width="350" lazy>
      <autenticar-smart-lock @fechar="authenticationDialog = false" @autenticado="authenticationDialog = false"></autenticar-smart-lock>
    </v-dialog>
    <v-dialog width="500" v-model="dialogNotificacao" v-if="dialogNotificacao">
      <notificacao-funcionario
        @fechar="fecharDialogNotificacao"
        :veiculo="veiculoSelecionado"
        :funcionario="funcionarioSelecionado"
        @carregarNotificacoes="pegarNotificacoes"
      />
    </v-dialog>
    <v-dialog v-model="dialogNegociacao" v-if="dialogNegociacao" width="55em" scrollable>
      <visualizar-proposta-veiculo
        @fechar="fecharDialogNotificacao"
        @carregar="pegarNotificacoes"
        :negociacao="negociacaoSelecionada"
        />
    </v-dialog>
    <v-dialog v-model="dialogResponderVeiculoAVenda" v-if="dialogResponderVeiculoAVenda"  width="50em" scrollable>
      <responder-solicitacao
        @error="showError"
        @success="showSuccess"
        @fechar="fecharDialogNotificacao"
        @carregar="pegarNotificacoes"
        :solicitacao="veiculoAVendaSelecionado"
      />
    </v-dialog>
  </v-img>
</template>

<script>
import VisualizarPropostaVeiculo from '@/components/dialogs/VisualizarPropostaVeiculo'
import EmpresaSelect from '@/components/dialogs/EmpresaSelect'
// import NewCorp from '@/components/cadastros/NewCorp'
import DialogInicioEmpresa from '@/components/dialogs/DialogInicioEmpresa'
import AutenticarSmartLock from '@/components/dialogs/AutenticarSmartLock'
import NotificacaoFuncionario from '@/components/dialogs/NotificacaoFuncionario'
import { version, name } from '../../../package.json'
import moment from 'moment'

export default {
  components: { EmpresaSelect, DialogInicioEmpresa, AutenticarSmartLock, NotificacaoFuncionario, VisualizarPropostaVeiculo },
  data: () => ({
    drawer: false,
    funcionarioSelecionado: undefined,
    veiculoSelecionado: undefined,
    negociacaoSelecionada: undefined,
    veiculoAVendaSelecionado: undefined,
    dialogNotificacao: false,
    dialogNegociacao: false,
    dialogVeiculoSelect: false,
    authenticationDialog: false,
    notificacoes: [],
    notifications: false,
    dialogResponderVeiculoAVenda: false,
    snackbar: {
      ativo: false,
      color: 'info',
      text: ''
    },
    items: [
      // { icon: 'dashboard', text: 'Dashboard', route: '/Dashboard' },
      { icon: 'business', text: 'Empresas', route: '/home', tipo: [0] },
      { icon: 'store', text: 'Ofertas', route: '/ofertas', tipo: [1] },
      { icon: 'handshake', text: 'Negociação de Veículos', route: '/veiculosparavender', tipo: [2] },
      { icon: 'swap_horizontal_circle', text: 'Repasse de Veículos', route: '/repasse', tipo: [2] },
      { icon: 'gavel', text: 'Disputas de Lance', route: '/leiloes', tipo: [2] },
      { icon: 'monitor_heart', text: 'Integração', route: '/integracao', tipo: [3, 8] },
      { icon: 'local_gas_station', text: 'Postos Integrados', route: '/postos-integrados', tipo: [3] },
      { icon: 'people', text: 'Colaborador', route: '/colaborador', tipo: [1] },
      { icon: 'inventory_2', text: 'Meus Produtos', route: '/meusprodutos', tipo: [4, 6] },
      { icon: 'home_repair_service', text: 'Meus Serviços', route: '/meusservicos', tipo: [5, 6] },
      { icon: 'people', text: 'Meus Clientes', route: '/meusclientes', tipo: [4, 5, 6] },
      { icon: 'groups', text: 'Meus Funcionários', route: '/meusfuncionarios', tipo: [4, 5, 6] },
      { icon: 'account_balance_wallet', text: 'Carteira Apetrus', route: '/carteiravirtual', tipo: [1, 2, 4, 5, 6, 7] },
      { icon: 'airport_shuttle', text: 'Gerenciar Frota', route: '/frota', tipo: [1] },
      { icon: 'airport_shuttle', text: 'Meus Veículos', route: '/veiculos', tipo: [2] },
      { icon: 'business_center', text: 'Propostas', route: '/propostas', tipo: [1] },
      { icon: 'shopping_basket', text: 'Negociações', route: '/negociacoes', tipo: [1] },
      { icon: 'currency_exchange', text: 'Propostas', route: '/propostas-veiculos', tipo: [2] },
      { icon: 'money', text: 'Minhas Recargas', route: '/meuscreditos', tipo: [1] },
      { icon: 'card_giftcard', text: 'Vouchers', route: '/vouchers', tipo: [1] },
      { icon: 'insert_drive_file', text: 'Relatórios', route: '/relatorio', tipo: [1, 4, 5, 6] },
      { icon: 'receipt_long', text: 'Minhas Faturas', route: '/minhasfaturas', tipo: [1, 2, 4, 5, 6] },
      { icon: 'cases', text: 'Permissões Concedidas', route: '/permissoes', tipo: [1] },
      { icon: 'currency_exchange', text: 'Meios de Recebimento', route: '/meios-de-recebimento', tipo: [4, 5, 6] },
      { icon: 'payment', text: 'Cartões', route: '/Cartoes', tipo: [1, 2] },
      { icon: 'manage_accounts', text: 'Editar Perfil', route: '/editarperfil', tipo: [0] },
      { icon: 'domain', text: 'Editar Empresa', route: '/editarempresa', tipo: [0] },
      { icon: 'settings', text: 'Configurações', route: '/configuracoes', tipo: [0] }
    ]
  }),
  watch: {
    versao (val) {
      this.$store.dispatch('verificarAtualizacao', {
        name: name,
        version: val
      })
    },
    user (val) {
      if (val === null) {
        this.$router.push('/')
      }
    },
    headers (val) {
      if (val !== null && val !== undefined) {
        this.listarEmpresas()
      }
    },
    error (val) {
      if (val) {
        this.Mostrarsnackbar(val, 'error')
        this.$store.dispatch('setError', null)
      }
    },
    success (val) {
      if (val) {
        this.Mostrarsnackbar(val, 'primary')
        this.$store.dispatch('setSuccess', null)
      }
    },
    empresa (val, valOld) {
      if (val && val !== valOld) {
        this.pegarNotificacoes()
      }
    },
    notifications (val) {
      if (val) {
        this.pegarNotificacoes()
      }
    }
  },
  created () {
    this.$store.dispatch('verificarAtualizacao', {
      name: name,
      version: version
    })
  },
  mounted () {
    if (this.headers) {
      this.listarEmpresas()
    }
    if (this.empresa) this.pegarNotificacoes()
  },
  computed: {
    formatedTimer () {
      const duration = moment.duration(this.timer, 'seconds')
      const tempHours = duration.hours()
      const tempMinutes = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes()
      const tempSeconds = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds()
      return `${tempHours}:${tempMinutes}:${tempSeconds}`
    },
    headers () {
      return this.$store.getters.headers
    },
    user () {
      return this.$store.getters.user
    },
    empresa () {
      return this.$store.getters.empresa
    },
    empresas () {
      return this.$store.getters.empresas
    },
    dialogEmpresa () {
      return this.user && this.empresas && this.empresas.length && !this.empresa
    },
    dialogCadEmpresa () {
      return this.user && ((this.empresas && this.empresas.length === 0) || (this.empresa && !this.empresa.termos_aceitos))
      // return this.user && ((this.empresas && this.empresas.length === 0) || (this.empresa && (!this.empresa.geoposition)) || (this.empresa && !this.empresa.termos_aceitos))
    },
    error () {
      return this.$store.getters.error
    },
    success () {
      return this.$store.getters.success
    },
    hasNewVersion () {
      return this.$store.getters.hasNewVersion
    },
    versao () {
      return this.$store.getters.versao
    },
    updateVersion () {
      return this.$store.getters.versao !== version
    },
    timer () {
      return this.$store.getters.timer
    },
    smartLock () {
      return this.$store.getters.smartLock
    },
    smartLockAtivo () {
      const pessoa = this.$store.getters.pessoa
      if (pessoa) {
        return pessoa.smartlock_empresa
      } else {
        return false
      }
    },
    menu_list () {
      //  Tipos de empresa
      // 1 - 'Controle de Frota'
      // 2 - 'Revenda de veículos'
      // 3 - 'Automação'
      // 4 - 'Venda de Produtos'
      // 5 - 'Prestação de Serviços'
      // 6 - 'Venda de Produtos e Serviços'
      // 7 - 'Instituição Beneficente'
      if (this.empresa) {
        return this.items.filter((item) => {
          if (item.tipo.includes(0)) return true
          return item.tipo.some(tipo => this.empresa.tipo_empresa === tipo)
        })
      } else {
        return [
          { icon: 'business', text: 'Empresas', route: '/home', tipo: 0 },
          { icon: 'manage_accounts', text: 'Editar Perfil', route: '/editarperfil', tipo: 0 },
          { icon: 'settings', text: 'Configurações', route: '/configuracoes', tipo: 0 }
        ]
      }
    }
  },
  methods: {
    formatarEmail (email) {
      const limiteCaracteres = 25
      if (email.length > limiteCaracteres) {
        return email.slice(0, limiteCaracteres) + '...'
      }
      return email
    },
    listarEmpresas () {
      this.$store.dispatch('minhasEmpresas')
    },
    showSuccess (info) {
      this.snackbar.color = 'primary'
      this.snackbar.text = info
      this.snackbar.active = true
    },
    showError (error) {
      this.snackbar.color = 'error'
      this.snackbar.text = error
      this.snackbar.active = true
    },
    ConfirmarVisualizacao (notificacao) {
      switch (notificacao.type) {
        case 'veiculo_avenda':
          this.$store.dispatch('confirmarVisualizacaoVeiculo', { empresa: this.empresa.cnpj, veiculo: notificacao.object.id_veiculo }).then(value => {
            if (value) {
              this.pegarNotificacoes()
            }
          })
          break
        default:
          this.showError('Você não pode realizar essa função.')
      }
    },
    responderNotificacao (notificacao) {
      this.$store.dispatch('responderNotificacao', {
        opcao: 5,
        cnpj_empresa: this.empresa.cnpj,
        id_notificacao: notificacao.object.id
      }).then(() => {
        this.pegarNotificacoes()
      })
    },
    pegarNotificacoes () {
      this.notificacoes = []
      this.$store.dispatch('pegarNotificacoes').then(data => {
        if (data.funcionarios) {
          data.funcionarios.map((funcionario) => {
            this.notificacoes.push({
              title: 'Solicitação de inclusão',
              subtitle: `${funcionario.nome_email} deseja fazer parte da equipe de funcionários`,
              type: 'funcionario',
              object: funcionario
            })
          })
        } if (data.veiculos) {
          data.veiculos.map((veiculo) => {
            this.notificacoes.push({
              title: 'Solicitação de inclusão',
              subtitle: `O veículo de placa ${veiculo.identificacao} faz parte da sua frota?`,
              type: 'veiculo',
              object: veiculo
            })
          })
        } if (data.veiculos_avenda && this.empresa.tipo_empresa === 2) {
          data.veiculos_avenda.map((veiculoAvenda) => {
            this.notificacoes.push({
              title: 'Novo veículo a venda',
              subtitle: `Um ${veiculoAvenda.modelo_marca ? veiculoAvenda.modelo_marca.toUpperCase() : ''} foi ofertado para sua empresa`,
              type: 'veiculo_avenda',
              object: veiculoAvenda,
              actions: [
                { text: 'Remover Notificação', color: 'primary', icon: 'close', function: this.ConfirmarVisualizacao }
              ]
            })
          })
        } if (data.negociacoes) {
          data.negociacoes.map((negociacao) => {
            if (negociacao.solicitacao.veiculo === null) {
              this.notificacoes.push({
                title: 'Proposta de valor',
                subtitle: `O veículo de placa ${negociacao.veiculo.identificacao} recebeu uma proposta`,
                type: 'negociacao_valor',
                object: negociacao
              })
            } else {
              this.notificacoes.push({
                title: 'Proposta de troca',
                subtitle: `O veículo de placa ${negociacao.veiculo.identificacao} recebeu uma proposta de troca`,
                type: 'negociacao_troca',
                object: negociacao
              })
            }
          })
        }
        if (data.notificacoes) {
          data.notificacoes.map((notificacao) => {
            this.notificacoes.push({
              title: notificacao.notificacao_title,
              subtitle: notificacao.notificacao_body,
              type: 'notificacao',
              object: notificacao,
              actions: [
                { text: 'Remover Notificação', color: 'primary', icon: 'close', function: this.responderNotificacao }
              ]
            })
          })
        }
      })
    },
    visualizar (object, type) {
      if (type === 'funcionario') this.funcionarioSelecionado = object
      else if (type === 'veiculo') this.veiculoSelecionado = object
      if (type === 'negociacao_troca' || type === 'negociacao_valor') {
        this.negociacaoSelecionada = object
        this.dialogNegociacao = true
      } if (type === 'veiculo_avenda') {
        this.VeiculoAVendaSelecionado = object
        this.dialogResponderVeiculoAVenda = true
      } else {
        this.dialogNotificacao = true
      }
    },
    fecharDialogNotificacao () {
      this.dialogNotificacao = false
      this.dialogNegociacao = false
      this.dialogResponderVeiculoAVenda = false
      this.veiculoSelecionado = undefined
      this.funcionarioSelecionado = undefined
      this.negociacaoSelecionada = undefined
      this.VeiculoAVendaSelecionado = undefined
    },
    saveEvent () {
      this.$store.dispatch('createEvent', this.event)
    },
    sair () {
      this.$store.dispatch('signUserOut')
    },
    closeEmpresa () {
      this.$store.dispatch('unselectEmpresa')
    },
    empresaSelected (empresa) {
      this.$store.dispatch('escolherEmpresa', empresa)
    },
    Mostrarsnackbar (text, color) {
      this.smartLockDialog = false
      this.snackbar.color = color
      this.snackbar.text = text
      this.snackbar.ativo = true
    },
    atualizar () {
      this.$store.dispatch('atualizarVersao', {
        name: name,
        version: version
      })
      window.location.reload(true)
    },
    restoreTime () {
      this.$store.dispatch('restoreTimer')
    }
  }
}
</script>

<style>
  .v-responsive__content {
    max-width: 100% !important
  }

  .my-style {
    padding: 10px;
    margin: 0 5px 5px;
    background: #44A4FC;
    border-left: 5px solid #187FE7;
  }
  .my-style .notification-title {
      font-size: 18px;
  }

  .my-style .notification-content {
    font-size: 14px;
  }
  @media screen {
    .hide-display {
      display: none;
    }
  }

  @media print {

    /* Inhaltsbreite setzen, Floats und Margins aufheben */
    /* Achtung: Die Klassen und IDs variieren von Theme zu Theme. Hier also eigene Klassen setzen */
    #content, #page {
      width: 100%;
      margin: 0;
      float: none;
    }

    /** Seitenränder einstellen */
    @page { margin: 1cm }

    /* Font auf 16px/13pt setzen, Background auf Weiß und Schrift auf Schwarz setzen.*/
    /* Das spart Tinte */
    body {
      font: 13pt Georgia, "Times New Roman", Times, serif;
      line-height: 1.3;
      background: #fff !important;
      color: #000;
    }

    body * {
      visibility: hidden;
      margin: 0;
    }

    .print {
      visibility: visible;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
    }

    .print * {
      max-width: 100%;
      visibility: visible;
    }

    h1 {
      font-size: 24pt;
    }

    h2, h3, h4 {
    font-size: 14pt;
    margin-top: 25px;
    }

    /* Alle Seitenumbrüche definieren */
    a {
        page-break-inside:avoid
    }
    blockquote {
        page-break-inside: avoid;
    }
    h1, h2, h3, h4, h5, h6 { page-break-after:avoid;
        page-break-inside:avoid }
    img { page-break-inside:avoid;
        page-break-after:avoid; }
    table, pre { page-break-inside:avoid }
    ul, ol, dl  { page-break-before:avoid }

    /* Linkfarbe und Linkverhalten darstellen */
    a:link, a:visited, a {
    background: transparent;
    color: #520;
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
    }

    a {
        page-break-inside:avoid
    }

    a[href^=http]:after {
          content:" <" attr(href) "> ";
    }

    a:after > img {
      content: "";
    }

    article a[href^="#"]:after {
      content: "";
    }

    a:not(:local-link):after {
      content:" <" attr(href) "> ";
    }

    /**
    * Eingebundene Videos verschwinden lassen und den Whitespace der iframes auf null reduzieren.
    */
    .entry iframe, ins {
        display: none;
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        line-height: 0pt !important;
        white-space: nowrap;
    }
    .embed-youtube, .embed-responsive {
      position: absolute;
      height: 0;
      overflow: hidden;
    }

    /* Unnötige Elemente ausblenden für den Druck */

    #header-widgets, nav, aside.mashsb-container,
    .sidebar, .mashshare-top, .mashshare-bottom,
    .content-ads, .make-comment, .author-bio,
    .heading, .related-posts, #decomments-form-add-comment,
    #breadcrumbs, #footer, .post-byline, .meta-single,
    .site-title img, .post-tags, .readability
    {
    display: none;
    }

    /* Benutzerdefinierte Nachrichten vor und nach dem Inhalt einfügen */
    .entry:after {
    content: "\ Alle Rechte vorbehalten. (c) 2014 - 2016 TechBrain - techbrain.de";
    color: #999 !important;
    font-size: 1em;
    padding-top: 30px;
    }
    #header:before {
    content: "\ Vielen herzlichen Dank für das Ausdrucken unseres Artikels. Wir hoffen, dass auch andere Artikel von uns Ihr Interesse wecken können.";
    color: #777 !important;
    font-size: 1em;
    padding-top: 30px;
    text-align: center !important;
    }

    /* Wichtige Elemente definieren */
    p, address, li, dt, dd, blockquote {
    font-size: 100%
    }

    /* Zeichensatz fuer Code Beispiele */
    code, pre { font-family: "Courier New", Courier, mono}

    ul, ol {
    list-style: square; margin-left: 18pt;
    margin-bottom: 20pt;
    }

    li {
    line-height: 1.6em;
    }
  }
</style>
